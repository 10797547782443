.nuclei-tabs{
    #search-results{
        .nav-tabs{
            border: none;
        }
        .nav-tabs>li.active>a{
            color: white;
            background-color: #2292d0;
        }
        .nav-tabs>li.active>a:hover{
            background-color: #2292d0;
        }
        .nav-tabs>li>a{
            background-color: #f9f9f9;
            margin: 0px -10px 0px 15px;
            color: #a7a5a7;
            border-radius: 4px 4px 4px 4px;
        }
        .nav-tabs>li>a:hover{
            background-color: #e6e6e6;
            border: none;
        }
        .tab-content{
            padding-top: 15px;
        }
    }
}