@import "../variables";

@mixin nuclei-button-color-mixin($color) {
    background: $color;
    &:focus,
    &:hover{
        background: lighten($color, 10%);
    }
    &.button-outline{
        background: lighten($color, 45%);
        color: $color;
        padding: 5px 12px 3.5px 12px;
        font-weight: 400;
        &.button-yellow{
            background-color:  lighten($color, 30%);
            color:  darken($color, 18%);
        }
    }
  }

.nuclei-button {
    font-family: $font-family;
    padding: 10px 20px;
    border: none;
    background: #0094f7;
    color: white;
    font-weight: 300;
    margin: 2px;
    font-size: 12px;
    &:focus,
    &:hover{
        outline: none;
        background: lighten($primary-blue, 10%);
    }
}

.button-blue{
    @include nuclei-button-color-mixin($primary-blue);
}

.button-red{
    @include nuclei-button-color-mixin($primary-red);
}

.button-yellow{
    @include nuclei-button-color-mixin($primary-yellow);
    color: black;
}

.button-gray{
    @include nuclei-button-color-mixin( $lightgray-gray-color);
    color:$lightgray-font-color;
    font-weight:600px;
    font-size:14px
}

.button-radius{
    border-radius: 4px;
}

.button-rounded{
    border-radius: 20px;
}

.button-sliced{
    border-radius: 0 0 4px 4px;
}

.button-small{
    padding: 4px 14px;
    font-size: 8px;
    border-radius: 2px;
}
.nuclei-bottom-gray-button:hover{
    color: black !important;
    font-weight: 400;
     
}