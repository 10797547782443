#box-a {
    font-family: "Open Sans", sans-serif;
  
    padding: 20px 20px 20px 20px;
    h2 {
      color: #0094f7;
      span {
        font-weight: bold;
      }
    }
    #box-d {
      font-family: "Open Sans", sans-serif;
    }
  }
  #box-c {
    font-family: "Open Sans", sans-serif;
    padding: 20px 20px 20px 20px;
    background-color: #fafafa;
    margin-bottom: 20px;
  }
  #box-b {
    font-family: "Open Sans", sans-serif;
    padding: 20px 20px 20px 20px;
    background-color: #fafafa;
    color: #0094f7;
  
    #option-1 {
      font-family: "Open Sans", sans-serif;
      height: auto;
      width: auto;
      padding: 30px 30px 30px 30px;
      margin-right: 20px;
    }
    #option-2 {
      font-family: "Open Sans", sans-serif;
      height: auto;
      width: auto;
      padding: 30px 30px 30px 30px;
      margin-right: 20px;
    }
    #model-year {
      input {
        margin-bottom: 10px;
      }
    }
  }
  #vehical-registration {
    #option-1 {
      font-family: "Open Sans", sans-serif;
      height: auto;
      width: auto;
      padding: 30px 30px 30px 30px;
      margin-right: 20px;
    }
    #option-2 {
      font-family: "Open Sans", sans-serif;
      height: auto;
      width: auto;
      padding: 30px 30px 30px 30px;
      margin-right: 20px;
    }
    li {
      text-align: left;
      p {
        font-size: 18px;
        margin: 0px;
        padding: 10px 0px 0px 0px;
        color: #0094f7;
      }
    }
  
    #street {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    #city {
      padding-bottom: 10px;
    }
    
  }
  
  #privacy-policy {
    margin-top: 28px;
    margin-bottom: 20px;
  }
  
  .center {
    height: 100px;
    top: 70px;
    left: 50px;
    border-radius: 3px;
  }
  
  .title {
    font-size: 16px;
    color: #676767;
    line-height: 50px;
    height: 50px;
    border-bottom: 1px solid #d8d8d8;
    text-align: center;
  }
  
  .dropzone {
      // position: absolute;
      z-index: 1;
      box-sizing: border-box;
      display: table;
      table-layout: fixed;
      width: 100px;
      height: 80px;
      top: 86px;
      left: 100px;
      // border: 1px dashed #A4A4A4;
      border-radius: 3px;
      text-align: center;
      overflow: hidden;
  
      &.is-dragover {
          border-color: #666;
          background: #eee;
      }
  
      .content {
          display: table-cell;
          vertical-align: middle;
      }
  
      .upload {
          margin: 6px 0 0 2px;
      }
  
      .filename {
          display: block;
          color: #e5e5e5;
          font-size: 14px;
          line-height: 18px;
      }
  
    .input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
  
  .dropzone1 {
    // position: absolute;
    z-index: 1;
    box-sizing: border-box;
    display: table;
    table-layout: fixed;
    width: 100px;
    height: 12px;
    top: 86px;
    left: 100px;
    // border: 1px dashed #A4A4A4;
    border-radius: 3px;
    text-align: center;
    overflow: hidden;
  
    &.is-dragover {
        border-color: #666;
        background: #eee;
    }
  
    .content {
        display: table-cell;
        vertical-align: middle;
    }
  
    .upload {
        margin: 6px 0 0 2px;
    }
  
    .filename {
        display: block;
        color: #cccccc;
        font-size: 16px;
        line-height: 18px;
    }
  
  .input {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
  }
  }
  
  
  .upload-btn {
    position: absolute;
    width: 140px;
    height: 40px;
    left: 80px;
    bottom: 24px;
    background: #6ece3b;
    border-radius: 3px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    box-shadow: 0 2px 0 0 #498c25;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  
    &:hover {
      box-shadow: 0 2px 0 0 #498c25, 0 2px 10px 0 #6ece3b;
    }
  }
  
  .bar {
    position: absolute;
    z-index: 1;
    width: 300px;
    height: 3px;
    top: 49px;
    left: 0;
    background: #6ece3b;
    transition: all 3s ease-out;
    transform: scaleX(0);
    transform-origin: 0 0;
  
    &.active {
      transform: scaleX(1) translate3d(0, 0, 0);
    }
  }
  
  .syncing {
    position: absolute;
    top: 109px;
    left: 134px;
    opacity: 0;
  
    &.active {
      animation: syncing 3.2s ease-in-out;
    }
  }
  
  .done {
    position: absolute;
    top: 112px;
    left: 132px;
    opacity: 0;
  
    &.active {
      animation: done 0.5s ease-in 3.2s;
      animation-fill-mode: both;
    }
  }
  #imageUpload-text {
    text-align: Right;
    padding-left: 80px;
    padding-top: 35px;
  }
  
  @keyframes syncing {
    0% {
      transform: rotate(0deg);
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      transform: rotate(360deg);
      opacity: 0;
    }
  }
  
  @keyframes done {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @media (min-width: 992px) {
    .dropzone {
      // position: absolute;
      z-index: 1;
      box-sizing: border-box;
      display: table;
      table-layout: fixed;
      width: 100px;
      height: 80px;
      top: 0px;
      left: 12px;
      // border: 1px dashed #A4A4A4;
      border-radius: 3px;
      text-align: center;
      overflow: hidden;
    }
  }
  
  .vehicle-info-list-style {
    list-style: none;
  }
  
  #vehical-registration .vehicle-info-list-style div {
    padding-left: 0;
  }
  
  @media (max-width: 400px) {
      #vehical-registration .vehicle-info-list-style input{
          font-size: 11px;
      }
  }
  
  .privacy-span{
    color: #868686; 
  
  }
  
  .payment-clear{
  
    border: none;
    color: #afafaf; 
  
  }
  
  .fo{
    font-size:20px
  }