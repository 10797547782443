#brand-logo-faq-modal{
    width: 16%;
    float: left;
    margin-left: 55px;
}

.faq-table{
    width: '100%'; 
    border-collapse: collapse;
}

.faq-table tbody td {
    border: 1px solid black;
}

.faq-table thead th {
    border: 1px solid black;
}

.ci-faq-collapse {
    background-color: #fcdb06 !important;
    margin-left: 10px;
    margin-right: 10px;
}
.ci-faq-collapse-title-text{
   font-weight: bold;
}