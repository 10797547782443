.form-control-auto{
  margin: 11px 0;
  margin-bottom: 7px; 
  border-radius: 4px;
}
.css-1aya2g8{
  box-shadow: none;
  min-height: 25px !important;
  border-color: #fafafa !important;
}
.css-1rtrksz{
  font-family: sans-serif;
  font-weight: 500;
  border-color: #fafafa !important;
  margin: 0px 0;
  line-height: 1.42857143;
  height: 43px;
  margin-top: -3px;
}
.css-2o5izw:hover{
  box-shadow: none;
  border-color: #fafafa !important;
}
.css-2o5izw{
  box-shadow: none !important;
  border-color:#fafafa !important;
}

.css-11unzgr{
  max-height: 250px !important;
}
.css-d8oujb{
  background-color: transparent !important;
}
.css-10nd86i:hover{
  border: 1px solid #e2e2e2;
  // transition: white ease-in-out 0.5s, box-shadow ease-in-out 0.8s;
}

.css-10nd86i{
  border: 1px solid transparent;
  // transition: white ease-in-out 0.5s, box-shadow ease-in-out 0.8s;
}
.css-1492t68{
  color: #dcdcdc !important;
}