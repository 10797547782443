@import "../../_variables.scss";
/* Sticky footer styles
-------------------------------------------------- */

html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 60px;
    /* Margin bottom by footer height */
}

.footer {
    position: relative;
    margin-top: -150px;
    /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 20px;
}

.navbar-wrapper {
    padding-bottom: 50px;
}

html,
body,
#root,
#layout {
    height: 100%;
}

#footer-image {
    background-image: url("../../assets/footer-bg.png");
    background-repeat: no-repeat;
    background-size: auto;
}

#wrap {
    min-height: 100%;
}

#main {
    padding-bottom: 150px;
    /* this needs to be bigger than footer height*/
}

.footer {
    position: relative;
    margin-top: -150px;
    /* negative value of footer height */
    height: 150px;
    clear: both;
    padding-top: 59px;
    text-align: left;
    .footer-title {
        font-size: 14px;
        color: $primary-font-color;
        font-weight: 500;
        margin-bottom: 2px;
    }
    .footer-description {
        font-size: 12px;
        color: $primary-font-color;
        font-weight: 500;
        margin-bottom: 2px;
    }
    .footer-icon {
        img {
            width: 25px;
            height: 25px;
        }
    }
    .footer-sub-icon {
        img {
            width: 30px;
            height: 30px;
        }
    }
    .social-icons {
        position: absolute;
        left: 340px;
        bottom: 34px;
        padding-left: 50px;
    }
    .footer-yellow-bar {
        width: 100%;
        background-color: $primary-yellow;
        z-index: -1;
        height: 20px;
        left: 0;
        position: absolute;
        bottom: 0px;
    }
}

@media(max-width:980px) {
    .footer {
        .social-icons {
            position: absolute;
            left: -34px;
            bottom: 80px;
        }
        .footer-yellow-bar {
            height: 120px;
        }
    }
}

@media (max-width: 768px) {
    .social-icons {
        padding-left: 0px;
    }
}

@media (max-width: 550px) {
    .footer {
        .footer-yellow-bar {
            height: 120px;
        }
        .social-icons {
            position: absolute;
            left: -35px;
            bottom: 80px;
        }
    }
}

#footer-image {
    position: absolute;
    left: 0;
    bottom: 0;
    height: 90px;
    z-index: -2;
}

@media (min-width: 768px) {
    .navbar-right~.navbar-right {
        margin-right: 20px;
    }
}

.background-image {
    background-image: url("../../assets/footer-bg.png");
    background-repeat: no-repeat;
    background-size: auto;
}

.navbar-bounce-item {
    position: absolute;
    right: 0px;
}