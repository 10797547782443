.mi-label {
  padding-left: 5% !important;
}
.mi-lable-edit{
  padding-left: 6% !important;
}

.dialogHeader {
  right: 15px;
  position: absolute;
  width: 150px;
  background: white;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;

  .dialogHeaderImg {
    width: inherit;
    padding: 10px;
  }
}

.side-panel-title {
  overflow: hidden;
  white-space: nowrap;
  padding-top: 50px;

  img {
    width: 100px;
  }

  .main-title {
    background: url("../../assets/ci/side-panel-guarder.png") no-repeat;
    font-size: 45px;
    color: white;
    font-weight: bold;
    width: 80%;
    height: 107px;
    background-size: 85px;
    padding-top: 45px;
    padding-left: 82px;
    display: block;
    margin: auto;
  }
}

.faq-container {
  position: absolute;
  bottom: 0px;
  right: 25px;
  background: #fff;
  padding: 10px 20px 0px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;

  p {
    font-weight: bold;
  }
}

.sideHangerImg {
  width: 120px;
  top: -22px;
  left: -15px;
  position: absolute;
}

.cover-descript {
  color: #1174c0;
  font-size: 16px;
  margin-top: 20px;
  font-weight: bold;
}

.side-panel-button-group {
  .side-panel-button {
    display: block;
    width: 80%;
    margin: auto auto 5px;
    height: 40px;
    background: #00aeef;
    border-radius: 0;
    font-weight: bold;
    color: white;
    font-size: 20px;
  }
}

.side-panel-providers {
  background: white;
  margin-top: 6%;
  border-radius: 10px;
  margin-left: 4%;
  margin-right: 4%;

  .providers-provider-block {
    width: 100%;
    padding-bottom: 15px;
    padding-top: 15px;

    img {
      width: 21%;
      padding: 2px;
      margin: 5%;
    }

  }

  .buddy-providers-block {
    width: 100px;
    margin-top: 30px;
  }
}

.buddy-message-container {
  position: absolute;
  bottom: 60px;

  p {
    color: #fc0d1b;
    font-weight: bold;
    font-size: 20px;
    width: 70%;
    padding: 15px 30px 10px;
    background: white;
    border-radius: 25px;
    border-bottom: 10px #fc0d1b solid;
  }

  p:after {
    content: "";
    border-bottom: 20px solid white;
    border-left: 20px solid white;
    border-top: 20px solid transparent;
    border-right: 20px solid transparent;
    position: absolute;
    right: 104px;
    bottom: 40px;
  }

  img {
    width: 120px;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.form-input-error {
  margin-top: 0px !important;
}

.policy-holder-avatar {
  cursor: pointer;
}

.clickable {
  cursor: pointer;
}

.company-row {
  .company-column {
    width: 15%;
    padding: 3px;
    vertical-align: middle;
  }

  .ilness-column {
    vertical-align: middle;

    p {
      font-size: 18px;
      padding: 7px;
      text-align: left;
      margin: 0px;
    }
  }
}
.Overlay{
  width: inherit;
  height: inherit;
}
.illnes-row {
  border-bottom: 1px solid #ddd;

  td {
    vertical-align: middle !important;
  }

  .cover-status {
    width: 15%;
  }

  .illnes-checked.glyphicon-ok-circle:before {
    color: #8be085;
  }

  .illnes-checked.glyphicon-remove-circle:before {
    color: rgb(216, 18, 45);
  }

  .illnes-name {
    text-align: left;
    margin-bottom: 0px;
    margin-left: 5px;
  }

  .not-covered-company {
    width: 20%;

    img {
      width: 55%;
    }
  }
}

.settlement-heading {
  text-align: left;
  background: #ebebeb;
  width: 100%;
  padding: 10px 0px 5px 35px;

  .company-logo-container {
    width: 70px;
    display: inline-block;
    background: white;
    border: 1px solid #cebfbf;
    border-radius: 50%;
    height: 70px;
    overflow: hidden;
    margin: 0 10px 0 0;
  }

  .company-logo {
    width: 100%;
    display: inline-block;
    position: relative;
    top: 18px;
  }

  .company-name {
    display: inline-block;
    width: 60%;
    margin-left: 0;
    position: absolute;
  }
}

.settlement-sub-limit {
  padding: 15px 0px 13px 60px;
  width: 90%;

  .sub-limit-dec {
    margin: 0px;
  }

  .sub-limit-name {
    margin: 0px;
    font-weight: bold;
  }
}


@media(max-width: 415px) {
  .nuclei-input-label {
    padding-left: 10% !important;
  }
  .cart-widget .cart-totle{
    font-size: 14px; 
  }
  .cart-widget {
    right: 0 !important;
  }
}


@media screen and (min-width: 320px) {
  .side-panel-title {
    padding-top: 65px;
  }
  .side-panel-title .main-title {
    font-size: 28px;
    width: 97%;
    height: 15vh;
    background-size: 60px;
    padding-top: 35px;
    padding-left: 25%;
  }
  .side-panel-title .ci-log-image{
    height: 100%;
    width: 100%;
    margin-top: 31px;
  }
  .buddy-message-container {
    width: 100%;
    bottom: 75px;
  }
  .buddy-message-container p:after {
    content: "";
    border-bottom: 12px solid white;
    border-left: 12px solid white;
    border-top: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    right: 40%;
    bottom: 28px;
  }
  .buddy-message-container p {
    color: #fc0d1b;
    font-weight: bold;
    font-size: 10px;
    width: 55%;
    padding: 10px 20px 5px;
    background: white;
    border-radius: 15px;
    border-bottom: 5px #fc0d1b solid;
  }
  .buddy-message-container img {
    width: 100px;
    right: 30px;
    top: -25px;
  }
  .cover-descript {
    font-size: 10px;
  }

  .side-panel-providers .buddy-providers-block {
    width: 80px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 320px) {
  .side-panel-title {
    padding-top: 65px;
  }
 
  .side-panel-title .main-title {
    font-size: 28px;
    width: 80%;
    height: 15vh;
    background-size: 60px;
    padding-top: 35px;
    padding-left: 25%;
  }
  .side-panel-title .ci-log-image{
    height: 100%;
    width: 100%;
    margin-top: 31px;
  }
  .buddy-message-container {
    width: 100%;
    bottom: 75px;
  }
  .buddy-message-container p:after {
    content: "";
    border-bottom: 12px solid white;
    border-left: 12px solid white;
    border-top: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    right: 40%;
    bottom: 28px;
  }
  .buddy-message-container p {
    color: #fc0d1b;
    font-weight: bold;
    font-size: 10px;
    width: 55%;
    padding: 10px 20px 5px;
    background: white;
    border-radius: 15px;
    border-bottom: 5px #fc0d1b solid;
  }
  .buddy-message-container img {
    width: 100px;
    right: 30px;
    top: -25px;
  }
  .cover-descript {
    font-size: 10px;
  }

  .side-panel-providers .buddy-providers-block {
    width: 80px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 375px) {
  .side-panel-title .main-title {
    font-size: 35px;
    width: 100%;
    height: 18vh;
    background-size: 70px;
    padding-top: 40px;
    padding-left: 20%;
  }
  .side-panel-title .ci-log-image{
    height: 100%;
    width: 100%;
    margin-top: 31px;
  }
  .buddy-message-container {
    width: 100%;
    bottom: 75px;

    p {
      color: #fc0d1b;
      font-weight: bold;
      font-size: 12px;
      width: 55%;
      padding: 10px 20px 5px;
      background: white;
      border-radius: 15px;
      border-bottom: 5px #fc0d1b solid;
    }

    p:after {
      content: "";
      border-bottom: 12px solid white;
      border-left: 12px solid white;
      border-top: 12px solid transparent;
      border-right: 12px solid transparent;
      position: absolute;
      right: 41%;
      bottom: 50%;
    }

    img {
      right: 15%;
      top: -25%;
    }
  }
  .cover-descript {
    font-size: 14px;
  }

  .side-panel-providers .buddy-providers-block {
    width: 80px;
    margin-top: 25px;
  }
}

@media screen and (min-width: 411px) {
  .side-panel-title .main-title {
    font-size: 40px;
    width: 100%;
    height: 100px;
    background-size: 70px;
    padding-top: 38px;
    padding-left: 50px;
  }
  .side-panel-title .ci-log-image{
    height: 100%;
    width: 100%;
    margin-top: 31px;
  }
  .buddy-message-container {
    width: 100%;
    bottom: 75px;
  }
  .buddy-message-container p:after {
    content: "";
    border-bottom: 12px solid white;
    border-left: 12px solid white;
    border-top: 12px solid transparent;
    border-right: 12px solid transparent;
    position: absolute;
    right: 35%;
    bottom: 45%;
  }
  .buddy-message-container p {
    color: #fc0d1b;
    font-weight: bold;
    font-size: 13px;
    width: 60%;
    padding: 10px 20px 8px;
    background: white;
    border-radius: 15px;
    border-bottom: 5px #fc0d1b solid;
  }
  .buddy-message-container img {
    right: 10%;
    top: -22%;
  }
}

@media screen and (min-width: 768px) {
  .side-panel-title .main-title {
    font-size: 45px;
    width: 53%;
    height: 100px;
    background-size: 80px;

  }
  .side-panel-title .ci-log-image{
    
  }
  .buddy-message-container {
    width: 100%;
    bottom: 75px;
  }
  .buddy-message-container p:after {
    content: "";
    border-bottom: 15px solid white;
    border-left: 15px solid white;
    border-top: 15px solid transparent;
    border-right: 15px solid transparent;
    position: absolute;
    right: 27%;
    bottom: 45%;
  }
  .buddy-message-container p {
    color: #fc0d1b;
    font-weight: bold;
    font-size: 20px;
    width: 50%;
    padding: 15px 45px 12px;
    background: white;
    border-radius: 15px;
    border-bottom: 5px #fc0d1b solid;
    float: right;
    margin-right: 30%;
  }
  .buddy-message-container img {
    right: 7%;
    top: -22%;
    width: 20%;
  }
}

@media screen and (min-width: 1024px) {
  .side-panel-title .main-title {
    font-size: 40px;
    width: 85%;
    height: 100px;
    background-size: 80px;
    padding-left: 25%;
  }

  .side-panel-title .ci-log-image{
    height: 100%;
    width: 100%;
    margin-top: 31px;
  }

  .buddy-message-container {
    width: 100%;
    bottom: 75px;

    p {
      color: #fc0d1b;
      font-weight: bold;
      font-size: 15px;
      width: 60%;
      padding: 10px 20px 10px;
      background: white;
      border-radius: 15px;
      border-bottom: 5px #fc0d1b solid;
      float: right;
      margin-right: 40%;
    }

    p:after {
      content: "";
      border-bottom: 20px solid white;
      border-left: 20px solid white;
      border-top: 20px solid transparent;
      border-right: 20px solid transparent;
      position: absolute;
      right: 35%;
      bottom: 45%;
    }

    img {
      right: 7%;
      top: -22%;
      width: 30%;
    }
  }
}

.btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
  outline: none !important;
  outline: none !important;
  outline-offset: unset !important;
}

.slider {
  padding: 30px 50px;

  .logo-container {
    width: 75px;
    height: 75px;
    background: white;
    overflow: hidden;
    border-radius: 50%;
    border: 1px solid #b7b4b4;

    img {
      width: 100%;
      display: inline-block;
      position: relative;
      top: 18px;
    }

  }

  .logo-container.active::after {
    content: '';
    position: absolute;
    background: url(https://insureme.lk/assets/images/ci/logo-active-indicator.png);
    width: 25px;
    height: 25px;
    background-repeat: no-repeat;
    background-size: 25px;
    top: 0px;
    left: 70px;
  }

  .logo-container.active {
    border: 4px solid rgb(164, 229, 246)
  }
  .logo-container-prohibited{
    position: absolute;
    width: 75px;
    height: 75px;
    z-index: 2;
    background: transparent;
    top:0;
    left: 15px;
  }
}

.rangeslider-horizontal {

  .rangeslider__handle-tooltip {
    width: auto !important;
    padding: 5px;
    background-color: transparent !important;
    top: -60px !important;

    span {
      color: #29e96d;
      font-size: 20px;
    }
  }

  .rangeslider__handle-tooltip:after {
    display: none;
  }

  .rangeslider__handle {
    width: 40px !important;
    height: 40px !important;
    outline: none !important;
  }


  .rangeslider__handle:after {
    content: '||' !important;
    font-size: 12px !important;
    width: 30px !important;
    height: 30px !important;
    top: 4px !important;
    left: 4px !important;
    line-height: 28px;
    box-shadow: none !important;
    color: white;
    background-color: #29e96d !important;
  }

}

.rangeslider.rangeslider-horizontal {
  margin-top: 35px;
}

.rangeslider__fill {
  background-color: #29e96d !important;
}

.rangeslider__labels {
  top: -30px;
  position: absolute !important;

  .rangeslider__label-item {
    width: 100px;
  }
}

.rangeslider .rangeslider__handle {
  border: 1px solid #fff !important;
  box-shadow: none !important;
}


@media screen and (min-width: 320px) {
  .add-new-user-lable {
    width: auto;
    font-size: 12px;
    padding: 0px !important;
    padding-right: 10px !important;
    margin: 0px !important;
  }
  .policy-holder-avatar {
    width: 80%;
  }
  .rangeslider__labels {
    top: -40px;
  }
}

@media screen and (min-width: 375px) {
  .add-new-user-lable {
    width: auto;
    font-size: 12px;
    padding: 0px !important;
    margin: 0px !important;
  }
}

@media screen and (min-width: 411px) {
}

@media screen and (min-width: 768px) {

}

@media screen and (min-width: 1024px) {
  .add-new-user-lable {
    width: 90px;
    font-size: 20px;
    padding: 0px !important;
    margin: auto !important;
  }
}

.company-column {
  .covered-company-logo-container {
    width: 70px;
    display: inline-block;
    background: white;
    border: 1px solid #cebfbf;
    border-radius: 50%;
    height: 70px;
    overflow: hidden;
    margin: 0 10px 0 0;

    .covered-company-logo {
      width: 100%;
      display: inline-block;
      position: relative;
      top: 18px;
    }
  }
}

.modal-links {
  .modal-link {
    width: 100%;
    display: inline-block;
    margin: 20px 0px 1px 20px;
    font-size: 17px;

    span {
      font-weight: bold;
    }
  }
}

.get-cover-container {
  .btn {
    border-radius: 50px;
    background: #2eb7fc;
    color: white;
    font-weight: bold;
    outline: none;

    div {
      height: 10px !important;
      width: 17px !important;
      float: right !important;
      margin: 2px 5px 4px 10px;
    }
  }
}

.privacy-acceptance {
  margin-top: 20px;

  input {
    margin: 10px;
    vertical-align: middle;
  }

  color: #7f8da2;
}

.add-new-user-lable {
  color: #a5a3a3;
}

.declarations-modal {
  padding: 10px !important;

  .container-declarations {
    width: 100%;
    border: 1px solid #d4cfcf;
    border-radius: 10px;
    padding: 10px;
    text-align: left;
    color: #676767;
    margin-top: 10px;

    .logo-container {
      display: inline-block;
      border: 1px solid black;
      border-radius: 50%;
      height: 70px;
      width: 70px;
      overflow: hidden;
      background: #fff;
      top: 10px;
      left: 10px;
      position: relative;

      img {
        top: 10px;
        position: relative;
        right: 5px;
      }
    }

    .company-title {
      display: inline-block;
      position: relative;
      bottom: 20px;
      left: 25px;
    }

    .holders-scroll-bar {
      overflow-x: auto;

      .policy-holder {
        padding: 15px 0px 15px 15px;
        cursor: pointer;

        .avatar {
          display: inline-block;

          img {
            width: 100%;
          }
        }

        .user-title {
          text-align: center;

          p {
            font-size: 15px;
            font-weight: 400;
            margin: 0;
            width: 100%;
            word-break: break-all;
          }

          p.active {
            color: #00aeef;
          }

          .relation {
            font-size: 12px;
            font-weight: 200;
          }


        }
      }

    }



    .cover-title {
      width: 100%;
      padding: 10px 10px 5px;

      p {
        font-weight: 400;
        font-size: 18px;
        margin: 0;
      }
    }

    .illnes-declaration {
      padding: 10px 10px 5px;

      .illness-title {
        font-size: 20px;
      }
    }

    .acceptance-declaration {

      .btn-container {
        display: inline-block;
        //width: 25%;

        .btn {
          border-radius: 10px !important;
          margin: 10px;
          display: inline-block;
        }

        .deactivated {
          color: #168fd0;
          border: 2px solid #168fd0;
        }

        .btn-danger {
          color: #fff;
        }

        .btn-success {
          color: #fff;
          background-color: #5cb85c;
          border-color: #4cae4c;
        }


      }

      .error-message {
        display: inline-block;
        //width: 70%;

        p {
          color: #e42d44;
          display: inline-block;
        }
      }

      .hereby-phase {
        font-size: 20px;
        font-weight: 400;
      }
    }

    .accept-final {
      text-align: left;

      p {
        font-size: 18px;
        font-weight: 400;
      }

      .btn {
        border-radius: 10px !important;
        margin: 10px;
        display: inline-block;
      }

      .btn-danger {
        color: #fff;
      }

      .btn-success {
        color: #fff;
        background-color: #5cb85c;
        border-color: #4cae4c;
      }

      .deactivated {
        color: #168fd0;
        border: 2px solid #168fd0;
      }
    }

    .company-container {
      background: #ebebeb;
      margin-top: 15px;

      img, p {
        display: inline-block
      }

      img {
        width: 70px;
        margin: 5px 10px 5px 5px;
      }

      p {
        font-size: 20px;
        font-weight: 300;
      }
    }

  }

  .buy-cover {
    padding: 25px;

    .btn {
      border-radius: 50px;
      background: #0491db;
      color: white;
      padding: 10px 40px;
      font-size: 20px;

      div {
        height: 30px !important;
        width: 15px !important;
        display: inline-block;
        position: relative;
        padding: 0px;
        margin: 0px 4px 0px 15px;
      }
    }
  }

}

.badge{
  background-color: red;
  font-size: 16px !important;
  position: absolute !important;
  right: 38px !important;
  top: 5px !important;
  cursor: pointer;
}

.personal-info-form {
  padding: 20px 35px;
}

.add-more {
  cursor: pointer;
  font-weight: bold;
}
.p0 {
  padding: 0 !important;
}
.faq-modal{
  cursor: pointer;
}

.company-hint-for-balnce{
  margin-bottom: 10px !important;
  font-style: italic;
  font-size: small;
  display: inline-block;
}

.policyholder-list{
  .nuclei-input-label.mi-lable-edit {
    //width: 100px!important;
  }
}

.company-settlement{
  text-align: left;
  padding-left: 5px;
  .company-container {
    background: #ebebeb;
    margin-top: 15px;

    img, p {
      display: inline-block
    }

    img {
      width: 70px;
      margin: 5px 10px 5px 5px;
    }

    p {
      font-size: 20px;
      font-weight: 300;
    }
    .logo-container {
      display: inline-block;
      border: 1px solid black;
      border-radius: 50%;
      height: 70px;
      width: 70px;
      overflow: hidden;
      background: #fff;
      top: 10px;
      left: 10px;
      position: relative;

      img {
        top: 10px;
        position: relative;
        right: 5px;
      }
    }
    .company-title {
      display: inline-block;
      position: relative;
      bottom: 20px;
      left: 25px;
    }
  }
}


@media screen and (min-width: 310px) and (max-width: 409px) {
  .personal-info-form {
    padding: 20px 0px;
  }
  .slider {
    padding: 16px 25px;
  }
  .nuclei-header-title-icon img {
    width: 18px;
  }
  .nuclei-header-title h3 {
    font-size: 17px;
    margin: 14px;
  }
  .nuclei-input-label {
    font-size: 12px;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    font-size: 10px !important;
    width: 22px !important;
    height: 22px !important;
    top: 4px !important;
    left: 4px !important;
    line-height: 20px;
  }
  .rangeslider__labels .rangeslider__label-item {
    font-size: 9px !important;

    p {
      font-size: 9px !important;
    }
  }
  .rangeslider__labels {
    top: -30px;
  }
  .rangeslider-horizontal {
    height: 9px !important;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 32px !important;
    height: 32px !important;
  }
  .slider .logo-container {
    width: 50px;
    height: 50px;
  }
  .slider .logo-container img {
    top: 9px;
  }
  .slider .logo-container.active {
    border: 2px solid #a4e5f6;
  }
  .policy-holder-avatar {
    width: 70%;
  }
  .slider .logo-container.active::after {
    left: 52px;
    background-size: 20px;
  }
  .rangeslider.rangeslider-horizontal {
    margin-top: 25px;
  }
  .nuclei-input-label {
    padding-left: 1% !important;
  }
  .form-group {
    margin-bottom: 7px !important;

  }
  .form-control {
    height: 30px;
  }
  .personal-info-form {
    padding: 10px 0px;
  }
  .declarations-modal .container-declarations .company-container p{
    font-size: 11px;
    font-weight: 300;
  }
  .declarations-modal .container-declarations .cover-title p{
    font-size: 10px;
  }

  .declarations-modal .container-declarations .illnes-declaration{
    p{
      font-size: 12px;
    }
  }

  .declarations-modal .container-declarations .acceptance-declaration .btn-container .btn{
    margin: 5px;
    font-size: 9px;
  }

  .declarations-modal .container-declarations .acceptance-declaration .error-message p{
    font-size: 11px;
  }
  .company-settlement .company-container p{
    font-size: 14px;
  }
  .company-settlement .company-container .logo-container img{
    top: 5px;
    right: 5px;
    width: 45px;
  }
  .company-settlement .company-container .logo-container{
    width: 45px;
    height: 45px;
  }
  .company-settlement .company-container .company-title{
    bottom: 7px;
  }
  .settlement-sub-limit {
    padding: 5px 0px 5px 25px;
    .sub-limit-name{
      font-size: 10px;
    }
  }
  .side-panel-button-group .side-panel-button{
    font-size: 15px;
  }
  .declarations-modal .container-declarations .logo-container{
    width: 50px;
    height: 50px;
  }
  .declarations-modal .container-declarations .logo-container img{
    top: 13px;
    right: 1px;
    margin: 0;
    width: 50px;
  }

  .declarations-modal .container-declarations .company-container{
    margin-top: 5px;
  }
  .declarations-modal .container-declarations .company-title{
    bottom: 10px;

  }


  .declarations-modal .container-declarations .illnes-declaration p{
    font-size: 10px;
  }

  .accept-final{
    p{
      font-size: 12px!important;
    }
    .btn{
      margin: 0;
      font-size: 9px;
    }
  }

}
@media screen and (min-width: 410px) and (max-width: 468px) {
  .personal-info-form {
    padding: 20px 0px;
  }
  .slider {
    padding: 20px 20px;
  }
  .nuclei-header-title-icon img {
    width: 18px;
  }
  .nuclei-header-title h3 {
    font-size: 17px;
    margin: 14px;
  }
  .nuclei-input-label {
    font-size: 12px;
  }
  .rangeslider-horizontal .rangeslider__handle:after {
    font-size: 10px !important;
    width: 22px !important;
    height: 22px !important;
    top: 4px !important;
    left: 4px !important;
    line-height: 20px;
  }
  .rangeslider__labels .rangeslider__label-item {
    font-size: 9px !important;

    p {
      font-size: 9px !important;
    }
  }
  .rangeslider__labels {
    top: -30px;
  }
  .rangeslider-horizontal {
    height: 9px !important;
  }
  .rangeslider-horizontal .rangeslider__handle {
    width: 32px !important;
    height: 32px !important;
  }
  .slider .logo-container {
    width: 65px;
    height: 65px;
  }
  .slider .logo-container img {
    top: 15px;
  }
  .slider .logo-container.active {
    border: 3px solid #a4e5f6;
  }
  .policy-holder-avatar {
    width: 70%;
  }
  .slider .logo-container.active::after {
    left: 65px;
    background-size: 20px;
  }
  .nuclei-input-label {
    padding-left: 1% !important;
  }
  .form-group {
    margin-bottom: 7px !important;

  }
  .form-control {
    height: 30px;
  }
  .personal-info-form {
    padding: 10px 0px;
  }
  .declarations-modal {
    padding: 5px !important;

    .container-declarations {
      width: 100%;
      border: 1px solid #d4cfcf;
      border-radius: 10px;
      padding: 10px;
      text-align: left;
      color: #676767;
      margin-top: 10px;

      .logo-container {
        display: inline-block;
        border: 1px solid black;
        border-radius: 50%;
        height: 70px;
        width: 70px;
        overflow: hidden;
        background: #fff;
        top: 10px;
        left: 10px;
        position: relative;

        img {
          top: 10px;
          position: relative;
          right: 5px;
        }
      }

      .company-title {
        display: inline-block;
        position: relative;
        bottom: 20px;
        left: 25px;
        font-size: 14px;
      }

      .holders-scroll-bar {
        overflow-x: auto;

        .policy-holder {
          padding: 15px 0px 15px 15px;
          cursor: pointer;

          .avatar {
            display: inline-block;

            img {
              width: 100%;
            }
          }

          .user-title {
            text-align: center;

            p {
              font-size: 15px;
              font-weight: 400;
              margin: 0;
              width: 100%;
              word-break: break-all;
            }

            p.active {
              color: #00aeef;
            }

            .relation {
              font-size: 12px;
              font-weight: 200;
            }


          }
        }

      }



      .cover-title {
        width: 100%;
        padding: 10px 10px 5px;

        p {
          font-weight: 400;
          font-size: 14px;
          margin: 0;
        }
      }

      .illnes-declaration {
        padding: 10px 10px 5px;

        .illness-title {
          font-size: 13px;
        }
        p{
          font-size: 13px;
        }
      }

      .acceptance-declaration {

        .btn-container {
          display: inline-block;
          //width: 25%;

          .btn {
            font-size: 10px;
            border-radius: 10px !important;
            margin: 5px;
            display: inline-block;
          }

          .deactivated {
            color: #168fd0;
            border: 2px solid #168fd0;
          }

          .btn-danger {
            color: #fff;
          }

          .btn-success {
            color: #fff;
            background-color: #5cb85c;
            border-color: #4cae4c;
          }


        }

        .error-message {
          display: inline-block;
          //width: 70%;

          p {
            color: #e42d44;
            display: inline-block;
          }
        }

        .hereby-phase {
          font-size: 20px;
          font-weight: 400;
        }
      }

      .accept-final {
        text-align: left;

        p {
          font-size: 14px;
          font-weight: 400;
        }

        .btn {
          border-radius: 10px !important;
          margin: 10px;
          display: inline-block;
        }

        .btn-danger {
          color: #fff;
        }

        .btn-success {
          color: #fff;
          background-color: #5cb85c;
          border-color: #4cae4c;
        }

        .deactivated {
          color: #168fd0;
          border: 2px solid #168fd0;
        }
      }

      .company-container {
        background: #ebebeb;
        margin-top: 15px;

        img, p {
          display: block;
        }

        img {
          width: 70px;
          margin: 5px 10px 5px 5px;
        }

        p {
          font-size: 14px;
          font-weight: 300;
        }
      }

    }

    .buy-cover {
      padding: 25px;

      .btn {
        border-radius: 50px;
        background: #0491db;
        color: white;
        padding: 10px 40px;
        font-size: 14px;

        div {
          height: 30px !important;
          width: 15px !important;
          display: inline-block;
          position: relative;
          padding: 0px;
          margin: 0px 4px 0px 15px;
        }
      }
    }
    .error-message{
      font-size: 10px;
    }
  }
  .company-settlement{
    text-align: left;
    padding-left: 5px;
    .company-container {
      background: #ebebeb;
      margin-top: 15px;

      img, p {
        display: block;
      }

      img {
        width: 70px;
        margin: 5px 10px 5px 5px;
      }

      p {
        font-size: 15px;
        font-weight: 300;
      }
      .logo-container {
        display: inline-block;
        border: 1px solid black;
        border-radius: 50%;
        height: 60px;
        width: 60px;
        overflow: hidden;
        background: #fff;
        top: 10px;
        left: 10px;
        position: relative;

        img {
          top: 8px;
          position: relative;
          right: 9px;
        }
      }
      .company-title {
        display: inline-block;
        position: relative;
        bottom: 15px;
        left: 25px;
      }
    }
  }
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .rangeslider__labels .rangeslider__label-item{
    top: 5px!important;
  }
  .personal-info-form {
    padding: 20px 25px;
  }
}
