.padding-right-sub{
    padding-right: 20px
}
.padding-left-sub{
    padding-left: 20px
}

.padding-right-sub-middle{
    padding-right: 10px
}
.padding-left-sub-middle{
    padding-left: 10px
}


.user-detail-list{
    margin-top: 65px;
    @media (max-width: 768px){
        padding: 0;
    }
}
