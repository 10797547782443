.personal-information {
    padding: 10px 0px 0px 0px;
}

.insurance-detail-header {
    background-image: url(../../../assets/bullseye.png);
    display: block;
    height: 10px;
}

.insurance-detail-title ul li {
    margin-left: -15px;
}

.nuclei-margin-top {
    margin-top: 40px
}

//new scss
.nuclie-collapse-insureme-form {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 1), 0 4px 3px 0 rgba(0, 0, 0, 0.01);
    display: block;
    position: static;
    padding-left: 4px; // padding-right:4px;
    padding-bottom: 10px;
}
.insurance-detail-card{
    display: flex;
    padding: 15px;
    margin-top: 10px;
    background-color: #f3f3f3;
    border-radius: 8px;
    &:hover{
        border: 1px solid #e2e2e2;
        transition: white ease-in-out 0.5s, box-shadow ease-in-out 0.8s
    }
    h4{
        font-size: 16px;
        margin-top: 10px;
        margin-bottom:-5px;
        width: 100%;
        text-align: center;
    }
    .insurance-image{
        padding: 10px;
        display: inline-block;
        vertical-align: initial;
        margin: 0 auto;
    }
}