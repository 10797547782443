

.paymentMethods
{
    height: 120px;

}



.paymentContainer{

background-color: white;
width: 40%;
height: auto;
float: left;
margin-left: 10px;
margin-top: 20px;

}


.image-block{
    
    width: 100%;
    height: 80px;
    text-align: left;
    float: left;
    margin-bottom: 10px;
    background-color: #fafafa;
    border-radius:5px;


}

.image-block:hover { 
    background-color: rgb(209, 209, 209);
}


.method-logo2
{
    padding-top: 10px;
    // display: inline-block;
    width: auto;
    height: 4em;
    margin: 7px 0px 0px 14px;

}

.paymentBlock{

    width: 100%;
    height: 80px;
    text-align: left;
    float: left;
    margin-bottom: 10px;
    background-color: #fafafa;
    border-radius:5px;
    

}
.paymentBlock:hover { 
    background-color: rgb(209, 209, 209);
}

.image-block-detailed{

    width: 40%;
    height: 100%;
    float: left;
}

.block-details{

    width: 60%;
    height: 100%;
    float: left;
    font-size: 10px;
    
}

#card-head{
    font-size: 12px;
    margin: 24px 0px 0px 6px;
}



@media (max-width : 768px){
    
    .paymentContainer{

        overflow-y : scroll;
        height: 600px;
        margin-left: -2px;
        
        }

    .optionContainer{

        margin: -10px 0px 0px 0px;

    }

    .promo-container{

        margin: -25px 0px 0px 0px;
    }

    .method-logo2 {
        padding-top: 10px;
        width: auto;
        margin: 7px 0px 0px 14px;
    }
    
           
}


@media (min-width : 768px){
    
    
    .paymentContainer{

        margin-left: -2px;

    }


}
