.upload-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
  overflow: hidden;
  max-width: 100%;
  padding-bottom: 15px;
  padding-top: 15px;
  border-radius: 20px;

  .FileInput {
    display: none !important;
  }

  .progress-bar {
    width: 100%;
    height: 8px;
    background-color: rgb(36, 140, 255);
    border-radius: 5px;
    margin-top: 10px;
  }

  .Files {
    margin-left: 32px;
    align-items: flex-start;
    justify-items: flex-start;
    flex: 1;
    overflow-y: auto;
  }

  .Actions {
    display: flex;
    flex: 1;
    width: 100%;
    align-items: flex-end;
    flex-direction: column;
    margin-top: 32px;
  }

  .Title {
    color: #555;
  }

  .Filename {
    font-size: 16px;
    color: #555;
  }

  .Row {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    height: 50px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
  }

  .CheckIcon {
    opacity: 0.5;
    margin-left: 32px;
  }

  .progress-wrapper {
    display: flex;
    flex: 7 15;
    flex-direction: row;
    align-items: center;
    margin-bottom: 1em;
  }

  button {
    font-family: "Roboto medium", sans-serif;
    font-size: 14px;
    display: inline-block;
    height: 36px;
    margin-left: 0.5em;
    padding: 6px 16px;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border: 0;
    border-radius: 2px;
    background: rgba(103, 58, 183, 1);
    color: #fff;
    outline: 0;
  }

  button:disabled {
    background: rgb(189, 189, 189);
    cursor: default;
  }

  .dropzone-container {
    img {
      height: 125px;
      background-size: cover;
    }
  }

  .Highlight {
    background-color: rgb(188, 185, 236);
  }

  .Icon {
    opacity: 1;
    height: 125px;
    margin-bottom: 10px;
  }

  #uploadedImage, #uploadedPdf {
    height: 125px;
  }

  #uploadedPdf {
    height: 125px;
    overflow:hidden !important;
  }

  .progress {
    background-color: rgb(21, 67, 133);
    height: 100%;
    margin: 0;
    border-radius: 5px;
  }

  .action-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 1em;
    text-align: center;
    .remove-image {
      background: rgba(255, 0, 18, 0.79);
    }
  }
}

.zoom-in-modal {
  position: relative !important;
  bottom: 0 !important;
  top: 10% !important;
  left: 0 !important;

  .ant-modal-content{
    background: transparent;
  }

  .preview {
    width: 100%;
    height: 60vh;
  }

  .ant-modal-close {
    display: none !important;
  }
}

.dropzone-content {
  background-color: #fff;
  margin-bottom: 1em;
  padding: 5px;
  display: flex;
  flex-direction: column;
  height: 275px;

  .dropzone-files{
    border-radius: 20px;
    font-size: 16px;
    border: 2px dashed rgb(187, 186, 186);
    padding: 20px;
  }
}

.preview-icon{
  margin-right: 5px;
  margin-top: -4px!important;
  position: relative;
}

.upload-title{
  height: 2.55em;
}

@media (min-width: 1700px) {
  .xl-2{
    width: 20%!important;
  }
}

