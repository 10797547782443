.nuclei-home-dock{
    width: 35vw;
    height: 33vw;
    background-color: rgba(121, 102, 150, 0.7);
    opacity: 0.8;
    border-radius: 10px;
    position: absolute;
    padding: 2px 25px 0px 22px;
}

@media (max-width: 767px) {
    .nuclei-home-dock{
        width: 90vw;
        height: 90vw;
        border-radius: 10px;
        left: 5vw;
        top: 47vw;
        padding: 0px 6vw 0vw 5vw;
        .nuclei-dock-grid-tile{
            left: -1vw;
        }
    }
}