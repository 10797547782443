
$featureLineHeight: 20.45px;

.nuclei-feature-card {
    
  text-align: left;
  margin: 10px 0;
  .feature-header{
      font-size: 22px;
      font-weight: 400;
      margin-bottom: 4px;
      padding-top: 12px;
      margin-left: 4px;
   
  }
  h3{
    margin-top: 0;
    padding-top:0;
  }
  &.shadow{
    border-radius: 3px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05), 0 4px 20px 0 rgba(0, 0, 0, 0.01);
  }

  &:hover{
    
  }

  .feature-image{
    width: 100%;
    border-radius: 6px 6px 0px 0px
  }

  .feature-body{
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 12px;
  }

  .text-content {
    padding-left: 5px;
    overflow: hidden;
    box-sizing: content-box;
    max-height:  $featureLineHeight * 4;
    margin-bottom: 10px;
    line-height: $featureLineHeight;
    font-size: 12px;
    color: #656565; // TODO: Get font Color
  }

  .nuclei-feature-badge{
    position: relative;
    left : 5px;
    font-weight: bold;
    top: 2px;
  }
}

.nuclei-feature-card-hover:hover {
  box-shadow: 0 10px 35px 0 rgba(0, 0, 0, 0.05), 0 4px 20px 0 rgba(0, 0, 0, 0.01);
}
