
#payment-details {
  margin-top: 10px;
}

#company-logo {
  background-color: #f9f9f9;
  padding: 20px 0px 20px 0px;
  margin: 0px 0px 10px 0px;
  text-align: left;
  

  h3 {
    
    margin: 18px 0px 0px 43px;
    font-size: 16px;
    color: #918d8d;
    font-weight: bold;
    
  }
}
#company-logo {
  padding-left: 10px;
  padding-right: 10px;
}

#offer {
  font-family: "Open Sans", sans-serif;
  background-color: #f9f9f9;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #555;
  margin: 20px 0px 0px 0px;
  #p1 {
    text-align: left;
    margin-left: 5px;
    span {
      color: #fc2d2b;
      font-weight: bold;
      font-size: 13px;
    
    }
  }
  #p2 {
    text-align: left;
    margin-left: 10px;
  }
}

#payment-head {
  font-size: 24px;
  margin: 80px 0px 30px 0px;
  color: #0094f7;
  // float: left;
}

#exampleInputEmail1 {
  border: none;
  background-color: #f7f7f7;
  box-shadow: none;
  height: 60px;
  font-size: 12px;
  
}

#exampleInputData {
  border: none;

  box-shadow: none;
  background-color: #b10e0e;
  text-align: center;
}

#notification {
  text-align: left;
  background-color: rgb(234, 81, 76);
  color: #f9f9f9;
  margin: 20px 0px 0px 0px;
  border-radius: 10px;
  height: 46px;
  font-size: 18px;
  
}


.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 1.2;
}

#close {
  color: #000000;
}

.leftBlock{

  background-color: green;
  height: 50px;
}

.rightBlock{

  background-color: blue;
  height: 50px;
}

.left-middleLeft-block{

  background-color: aqua;
  height: 50px;
}

.left-middleRight-block{

  background-color: gray;
  height: 50px;
}

.bottom-block{

  background-color: red;
  height: 50px;
}

.bottm-text{

  width:100%;
}
