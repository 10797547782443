.nuclei-Document-upload{
    padding-left: 25px;
    input[type="file"] {
        display: none;
    }
}

#plusIconSize, #documentIconSize{
    font-size: 60px;
    color: #e6e6e6;
}