@import '../../../variables';
#insurance-details #insurance-details-box {
    background-color: #fafafa;
    text-align: left;
    color: $primary-blue;
    border-radius: 8px;
}

#insurance-details {
    margin: 10px 0px 0px 0px;
}

.bt-buy-now {
    width: 50%;
    height: 100%;
    border: none;
    padding: 15px 0;
    font-weight: 400;
    font-size: 12px;
    background-color: $primary-blue;
    color: #fff;
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border-radius: 0px 0px 0px 8px;
}

.bt-buy-later {
    width: 50%;
    border: none;
    padding: 15px 0;
    font-weight: 400;
    font-size: 12px;
    height: 100%;
    background-color: $primary-dark-blue;
    color: #fff;
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border-radius: 0px 0px 8px 0px;
}

.image-logo {
    width: auto;
    height: 70px;
}

.panel-heading-landing {
    padding: 12px !important;
    position: relative;
}

#unchecked-image {
    width: 24px;
    height: 24px;
    z-index: 1;
    position: relative;
}

.panel-footer {
    padding: 0;
    height: 45px;
}

#more-info {
    font-weight: 400;
    color: #18a3ff;
    font-size: 13px;
    text-decoration: none !important;
}

.pricing-table {
    max-width: 368px;
    margin: 0 auto;
    transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    border-radius: 26px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06);
}

.edit-detail {
    background: #0094f7;
    color: white;
    border-radius: 20px;
    font-weight: 400;
    font-size: 12px;
    padding: 5px 20px;
    position: relative;
    margin-top: 20px;
    z-index: 100;
    margin-left: 1px;
    margin-top: 14px;
    float: right;
}

.insurance-detail-title {
    padding-left: 0px;
}

.clearfix {
    padding-left: 15px;
}

.nuclei-option {
    background: #f2f2f2;
    &:focus {
        background: red;
    }
}

@media (max-width: 450px) {
    #best-deal {
        width: 40px;
        height: auto;
        font-size: 8.5px;
        padding-top: 1px;
        border-radius: 3px;
    }
}

#html-spinner {
    width: 40px;
    height: 40px;
    border: 4px solid black;
    border-top: 4px solid white;
    border-radius: 50%;
}

#html-spinner,
#svg-spinner {
    -webkit-transition-property: -webkit-transform;
    -webkit-transition-duration: 1.2s;
    -webkit-animation-name: rotate;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-transition-property: -moz-transform;
    -moz-animation-name: rotate;
    -moz-animation-duration: 1.2s;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    transition-property: transform;
    animation-name: rotate;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-webkit-keyframes rotate {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}

@-moz-keyframes rotate {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.sub-button .nuclei-group>div {
    display: flex
}

.nuclei-show-all-button {
    float: right;
}
#compare-buttons{
    text-align: right;
    margin-top: 20px;
}

#compare-now {
    background: $primary-red;
    color: white;
    border-radius: 20px;
    font-weight: 400;
    font-size: 18px;
    padding: 12px 25px;
    padding-left: 40px;
    position: relative;
    margin-right: 8px;
    .shopping-icon {
        position: absolute;
        left: 12px;
        top: 7px;
        .glyphicon {
            font-size: 26px;
            color: black;
        }
        .badge {
            background: $primary-yellow;
            color: black;
            font-weight: 400;
            font-size: 10px;
            left: 19px;
            margin-top: 0.8em;
            width: 1em;
            top: 10px;
            position: absolute;
            padding: 2px 1px 1px 1px;
        }
    }
    &:focus{
        outline: none;
    }
}

@media (max-width: 576px) {
    #compare-now {
        border-width: 1px;
        position: absolute;
        z-index: 100;
        right: 24px;
        top: -8px;
    }
    .image-logo {
        height: 40px;
    }
}
.disabled-compare{
    background: #8a8a8a !important;
    border: none;
}