.multiple-doc-title{
	font-weight:bold;
	font-size:20px;
}
.policy-container {
  text-align: left;
  margin:20px 0 10px 0;
  background: #fff;
  border: 1px solid #bbbaba;
  border-radius: 10px;

  .row:nth-child(even) {
    background: #CCC
  }

  p {
    margin: 10px 0;
  }
}

