$UserLineHeight: 18px;

.nuclei-blog-caption {
  .nuclei-caption {
    padding: 10px 0px 0px 0px;
    color: #333;
  }

  .nuclei-user-details {
    padding: 0;
  }

  .nuclei-user-image {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  .user-img {
    width: 100px;
    height: 100px;
    margin: -60px 0px 0px -60px;
    position: absolute;
  }

  // media query for user image
  @media (max-width: 320px) {
    .user-img{
      width: 100px;
    }
}

@media only screen and (min-width: 1200px) {
  .user-img{
    width: 100px;
  }
}

  .nuclei-user-info-block {
    width: 100%;
    top: 55px;
    background: #f9f9f9;
    z-index: 0;
    padding: 30px 15px 5px 15px;
    border-radius: 5px;   
    min-height: 131px; 
  }

  .user-comment{
    padding-left: 5px;
    overflow: hidden;
    box-sizing: content-box;
    height:  $UserLineHeight * 3;
    margin-bottom: 10px;
    line-height: $UserLineHeight;
    font-size: 13px;
    color: #666666;
  }

  .user-name{
    width: 100%;
    margin: 10px 0;
    color: #ec2a2b;
    padding: 0px;
  }

  .nuclei-user-heading{
    width: 100%;
    text-align: center;
    margin: 10px 0 0;
  }
}
