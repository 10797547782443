.nuclei-insureme-partners-img{
    padding-top: 15px;
}

.nuclei-insureme-partners-img >div >a >img{
    width: 10vw;
    padding: 2px;
}

@media (max-width: 320px) {
    .nuclei-insureme-partners-img >div >a >img{
        width: 20vw;
    }
}

@media only screen and (min-width: 1200px) {
    .nuclei-insureme-partners-img >div >a >img{
        width: 10vw;
    }
}



@media(max-width: 767px){
    .nuclei-insureme-partners-img >div >a >img{
        width: 20vw;
        padding: 5px;
    }
}