.optionContainer{


width: 40%;
height: auto;
float: right;
margin-left: 10px;
margin-top: 10px;
padding-right: 10px;

}

.option-block {

    width: 100%;
    height : 150px;
    
    
}

#promo-code{

    color: #0094f7;
    font-size: 18px;
    float: left;
  
}

#price{
    font-family: "Open Sans", sans-serif;
    background-color: #f9f9f9;
    padding: 40px 0px 20px 0px;
    margin: 10px 0px -19px 0px;
    border-radius: 5px 5px 0px 0px;
    
    // float: right;
    #underline{
        color: #555;
        font-size: 22px;
        text-decoration: line-through;
        text-decoration-color: red;
        //margin: 4px 2px -9px 135px;
        color: #918d8d;
        text-align: center;
    }
    #p1{
        color: #0094f7;
        font-size: 43px;
        //margin: -10px 0px 0px 73px;
        text-align: center;

    }

}


#details{
    background-color: #f9f9f9;
    padding: 20px 0px 20px 30px;
    margin: 20px 0px 1px 0px;
    text-align: left;
    
    // float: left;
    font-size: 16px;
    color: rgb(161, 155, 155);
    p{
        margin-left: 24px;
        span{
            font-weight: bold;
            color: rgb(145, 141, 141);
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            
            
            
        }
    }
}

#company-logo h3 {
    
    margin-left: 18px;
    
}

#details p {
    margin-left: 48px;
    font-size: 16px;

}

#janashakhi-logo {
            width: auto;
            height: 80px;
            margin-top: 13px;
            margin-left: 75px;
            margin-right: 10px;
            float: left;
        }

// @media (max-width: 630px){

//     #price{
//         font-family: "Open Sans", sans-serif;
//         background-color: #f9f9f9;
//         padding: 40px 0px 20px 0px;
//         margin: 10px 0px -19px 0px;
        
//         // float: right;
//         #underline{
//             color: #555;
//             font-size: 16px;
//             text-decoration: line-through;
//             text-decoration-color: red;
//             //margin: 4px 2px -9px 135px;
//             color: #918d8d;
//             text-align: center;
//         }
//         #p1{
//             color: #0094f7;
//             font-size: 30px;
//             //margin: -10px 0px 0px 73px;
//             text-align: center;
    
//         }
//     }

// }

// @media (max-width:650) and (min-width:480){

//     #details p {
//         margin-left: 14px;
        
//     }


// }

// @media (max-width: 500px){

//     #details{
//         p{
//             margin-left: 18px;
//         }
//     }

// }

// @media (max-width: 480px){

//     #details{
//         p{
//             margin-left: 14px;
//         }
//     }

// }

// @media (max-width: 470px){

//     #price{
//         font-family: "Open Sans", sans-serif;
//         background-color: #f9f9f9;
//         padding: 40px 0px 20px 0px;
//         margin: 10px 0px -19px 0px;
        
//         // float: right;
//         #underline{
//             color: #555;
//             font-size: 14px;
//             text-decoration: line-through;
//             text-decoration-color: red;
//             margin: 7px 1px -12px 4px;
//             color: #918d8d;
//             text-align: center;F
//         }
//         #p1{
//             color: #0094f7;
//             font-size: 26px;
//             margin: 10px 0px 0px 0px;
//             text-align: center;
    
//         }
//     }

// }

// @media (max-width: 450px){

//     #details{
//         p{
//             margin-left: 10px;
//         }
//     }

// }

// @media (max-width: 430px){

//     #details{
//         p{
//             margin-left: 6px;
//         }
//     }

// }

// @media (max-width: 412px){

//     #details{
//         p{
//             margin-left: 2px;
//             font-size: 10px;
//         }
//     }

// }


// @media (max-width: 390px){

//     #price{
//         font-family: "Open Sans", sans-serif;
//         background-color: #f9f9f9;
//         padding: 40px 0px 20px 0px;
//         margin: 10px 0px -19px 0px;
        
//         // float: right;
//         #underline{
//             color: #555;
//             font-size: 10px;
//             text-decoration: line-through;
//             text-decoration-color: red;
//             margin: 7px 1px -12px 4px;
//             color: #918d8d;
//             text-align: center;
//         }
//         #p1{
//             color: #0094f7;
//             font-size: 20px;
//             margin: 10px 0px 0px 0px;
//             text-align: center;
    
//         }
//     }

// }











// @media (min-width: 580px) and (max-width : 992px){

//     #company-logo{
        
//         h3{
//             //margin: -58px 0px 0px 114px;
//             font-size: 16px;
//             color: #918d8d;
//             font-weight: bold;
//             margin-top: 20px;
//         }
//     }

//     #janashakhi-logo {
//         width: 50px;
//         height: 70px;
//         margin-top: 13px;
//         margin-left: 74px;
//         float: left;
//     }
//     }




    // @media (max-width: 530px){

    //     #company-logo h3 {
    //         margin: -56px 0px 0px 95px;
    //         font-size: 11px;
    //         color: #918d8d;
    //         font-weight: bold;
    //     }
    
    //     #janashakhi-logo {
    //         width: 48px;
    //         height: 66px;
    //         margin-top: 20px;
    //         margin-left: 41px;
    //         float: left;
    //     }
    // }

        
            // @media (max-width: 470px){

            //     #company-logo h3 {
            //         margin: -56px 0px 0px 93px;
            //         font-size: 9px;
                    
            //     }
            
            //     }
        

        // @media (max-width : 768px) {

        //     .optionContainer{

        //         margin-right: 18px;
        //         width: 51%;
        //     }

            

            
        // }

       
        @media (max-width : 992px) {

            #details p {
                margin-left: 29px;
            }

            #janashakhi-logo {
                margin-left: 47px;
                
            }
            #price #p1 {
                color: #0094f7;
                font-size: 38px;
                text-align: center;
            }
        }

        @media (max-width : 768px) {

            .optionContainer{

               // margin-right: 18px;
                width: 51%;
            }

            #price #p1 {
                color: #0094f7;
                font-size: 37px;
                margin-right: 0px
            }

            #details p {
                margin-left: 25px;
                font-size: 16px;
            }
        }

            @media (max-width:576px){
                #price #p1 {
                    font-size: 28px; 
            }
            #price #underline { 
                font-size: 17px;
                margin-right: 15px
                
            }
            #details p {
                margin-left: -12px;
                font-size: 15px;
            }

            #janashakhi-logo {
                margin-left: 10px;
            }

            #company-logo h3 {
                margin-left: 4px;
                margin-top: 27px;
                font-size: 10px;
                color: #918d8d;
                font-weight: bold;
            }
        }
        

        // @media (max-width : 662px) {

            

        //     #price #p1 {
                
        //         font-size: 29px;
               
        //     }

           
        //     #price #underline {
                
        //         font-size: 16px;
                
        //     }
        // }

        // @media (max-width : 694px) {

        //     #price #p1 {
        //         font-size: 33px;
        //     }

        //     #price #underline {
        //         font-size: 17px;
        //     }
            
        // }

        // @media (max-width : 686px) {

        //     #price #p1 {
        //         font-size: 30px;
        //     }

        //     #price #underline {
        //         font-size: 13px;
        //     }

            
        // }

    