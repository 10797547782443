@import "../variables";

.insureme-form {
  text-align: left;
  .form-group {
    text-align: left;
    margin-bottom: 2px;

    label {
      font-family: $font-family;
      text-align: left;
      color: $form-label;
      font-size: 16px;
      margin-bottom: 5px;
      margin-top: 2px;
      display:block
    }
    .optioncolor {
      label {
        color: black;
      }
    }
  }

  .form-control {
    display: inline;
    height: 43px;
    padding: 6px 10px;
    font-size: 14px;
    margin: 5px 0;
    line-height: 1.42857143;
    background: $gray-background;
    background-image: none;
    border-radius: 4px;
    -webkit-box-shadow: none;
    box-shadow: none;
    color: $primary-font-color;
    font-weight: 500;
    margin-bottom: 7px; 
    border: 1px solid transparent;
   &:hover{
     border: 1px solid #e2e2e2;
    //  transition: white ease-in-out 0.5s, box-shadow ease-in-out 0.8s
   }
  }

  
  .nuceli-textbox-top{
    margin-bottom: 16px;
    margin-top:-10px
  }

  .nuceli-white-box{
       background:white
     }


//Side Panel vehicle details scss
   .gray-body{
    background: $gray-background;
    margin-top: 10px;
    padding: 16px 0px;
    border-radius: 6px;
    .selection-option {
        background: white;
    }
    .form-control{
        background: white;
    }
}

.front-gray-body{
   background: $gray-background;
   padding: 16px;
  border-radius: 6px;
 
  margin-top: 2px;
  color:#a5a5a5
}
  

  @media (max-width: 500px) {
    .form-control {
      font-size: 12px;
      margin-bottom: 5px;
    }
    .form-group label {
      // margin-top: 2px;
      font-size: 13px;
      font-weight: 300;
    }
  }
}

.form-input-error{
  font-size: 10px;
  padding-left: 4px;
  color: #db1010;
  margin-top: -5px;
  text-align: left !important;
}

.form-input-has-error{
  border: 1px solid red !important;
}