@import "../variables";

.nuclei-group{
    display: table;
    padding-bottom:10px;
    .form-input-error{
        margin-top: 3px;
        margin-bottom: 0;
    }
}

#nuclei-margin-left
{
    padding-left: 15px
}
.nuclei-selection-option {
    font-family: $font-family;
    height: auto;
    min-width: 70px;
    padding: 15px 15px;
    color: #a8a7a8 !important;
    font-weight: 650px;
    text-align: center !important;
    margin-right: 12px;
    border-radius: 4px !important;
    margin-top: 1px !important; 
    background: $graylight-background;
    border: none;
    input {
        display: none;
    }
    &.active{
        color: white !important;
        background: $checkbox-blue !important;
        box-shadow:none

    }
    
}
.nuclei-selection-option.active:hover{
    background-color: $primary-blue !important; 
}
.nuclei-selection-option-disabled:hover {
    cursor: initial;
    background-color: $graylight-background !important; 
}
.no-options-button{
    margin-top: 15px;
}


.nuclei-tooltip{
    background-color: #feed9d;
    text-align: center;
    border: solid 0px #fff;
    margin-top: -27px;
    max-height: 23px;
    max-width: 23px;
    padding-left: 8px;
    padding-right: 10px;
    padding-top: 3px;
    margin-left: 10px;
    &:hover{
        background-color: #feed9d;
    }
    &:focus{
        background-color: #feed9d;
        outline: none;
    }
    &:active{
        background-color: #ffe56e;
        box-shadow: none;
        outline: none !important;
    }
}
// .nuclei-tooltip:hover{
//     background-color:#feed9d;
// }

// .nuclei-tooltip:active{
//     background-color:#feed9d;
// }
// .nuclei-tooltip:focus{
//     background-color:#feed9d;
    
// }

.nuclei-tooltip-text{

    background-color: #feed9d;
    border: solid 0px #fff;
    max-height: 23px;
    max-width: 23px;
    padding-left: 8px;
    padding-right: 10px;
    padding-top: 2px;
    right: 0;
    position: absolute;
    right: -17px;
    top: 10px;
}

@media (max-width:992px)
{
  .nuclei-buttongroup-input {
    // font-size: 13px;   
} 

.insureme-form .form-group label {  
    // font-size: 13px;
} 

.insureme-form .form-control {
    // font-size: 12px;
}
}


@media (max-width: 768px){
    .nuclei-buttongroup-input {
        // font-size: 10px;
    }

    .insureme-form .form-group label {
        // font-size: 10px;
    }
}


@media (max-width: 576px){
    .nuclei-selection-option {
    font-size: 2.3vw;
    padding: 3vw;
    font-weight: 600;
    
    }
    .nuclei-tooltip {
        margin-top: -22px;
        max-height: 17px;
        max-width: 20px;
        padding-left: 6px;
        padding-right: 5px;
        padding-top: 1px;
        padding-bottom: -7px;
        margin-left: 6px;
        font-size: 12px;
    }
    .nuclei-tooltip-text{
   background-color: #feed9d;
    border: solid 0px #fff;
    max-height: 23px;
    max-width: 23px;
    padding-left: 8px;
    padding-right: 10px;
    padding-top: 2px;
    right: 0;
    position: absolute;
    right: -17px;
    top: 10px;
    }
    .nuclei-sub-button-details
    {
        display: flex
    }

}

.tooltip-popover{ 
    border: none;
    box-shadow: none;
    background-color: transparent;
    // left: 194px !important;
    .popover-content{
        display: none;
        
    }
    .popover-title{
        padding: 3px 7px;
        font-size: 10px;
        margin-left: 56px;
        background-color: #feed9d;
        border-radius: 3px;
        margin-top: -8px;
        font-family: $font-family;
        font-weight: 300;
    }
    >.arrow:after{
        border-bottom-color: #feed9d !important;
        z-index: -1;
    }
    >.arrow{
        border-bottom-color:transparent !important;
    }
}
    

// @media (max-width: 400px){
   
    
//     }