.quote-form{
    background-color: #f7f7f7;
    padding: 4%;
}
#name-input, #email-input, #mobile-input, #company-input {
    border: none;
    background-color: #ffffff;
    box-shadow: none;
    height: 60px;
    font-size: 12px;
    margin-bottom: 12px;
}
.prmo-details{
    text-align: left;
    padding: 2%;
}
#headStyle {
    float: left;
    margin-top: 25px;
    padding-left: 2%;
}
.terms-cond {
    text-align: left;
    margin-top: 25px;
    padding-left: 2%;
}
.terms {
    margin-left: -26px;
}
#socialIcon {
    float: left;
}

#title {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
    h2 {
        color: #0094f7;
        span {
            font-weight: bold;
        }
    }
}

.email-input{
    border-radius: 5px 0px 0px 5px;
    height: 50px;
}

.bt-subscribe{
    border-radius: 0px 5px 5px 0px;
    background-color: #3093f8;
    width: 100%;
    height: 60px;
    color: white
}

.share-social{
    text-align: left;
    padding-left: 15px;
}

.share-social h2{
    font-weight: bold;
}

.share-social img{
    height: auto;
    width: 100%;
    float: left;
}
.nuclei-image-main{
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}

.nuclei-image-sub{
    width:Auto;
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}
.nuclei-image-sub-one{
    margin-top:18px;
    width:100%;
    border-radius: 8px;
}

 @media (max-width: 767px){
    .quick-tag-list-left{
        padding-left: 5px;
        float: right;
    }
    .quick-tag-list-right{
        padding-right: 5px;
    }
    .quick-tags{
        padding: 15px;
    }
    .nuclei-image-sub-one{
        margin-top:8px;
        width:100%;
        border-radius: 3px;
    }
 }


 @media (max-width: 576px) { 
    .nuclei-image-main{
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    
    .nuclei-image-sub{
        width:Auto;
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    .nuclei-image-sub-one{
        margin-top:5px;
        width:100%;
        border-radius: 3px;
    }

 }

 .banner-container
{
    margin-top: 10px;
}

.get-quote{
    display: inline-flex;
    margin-top: -140px;
    margin-right: 74%;
    background-color: white;
    color: black;

}

.more-info {
    display: inline-flex;
    margin-top: -180px;
    margin-right: 52%;
    background-color: white;
    background: transparent;
    border: inset;
    border-radius: 5px;
    width: 142px;
    border-color: white;
    height: 46px;
    color: white;
}

.header-title {
    text-align: left;
}

.list-item {
    font-size: 20px;
    text-align: left;
    padding-top: 24px;
}
.why-section{
    background-image: url('../../../assets/sme-product/bg-01.png');
}
.why-section h1{
    color: #fcd107;
}
.why-section p {
    text-align: left;
    font-size: 20px;
    color:white;
    line-height: 30px;
}

.why-not-section{
    background-image: url('../../../assets/sme-product/bg-02.png');
}

.why-not-section h1{
    color: black;
    text-align: center;
}

.list-title {
    font-size: 20px;
    margin-right: 90%;
}
.list-description {
    font-size: 16px;
}

.bold-para{
    font-weight: bold;  
    color: black;
    text-align: left;
}

.caption-title {
    margin-top: -223px;
    color: white;
    font-family: serif;
    font-weight: 500;
    width: 682px;
    margin-left: 103px;
    text-align: left;
}

// .ordered-list li::before {
//     border-radius: 50%;
//     height: 20px;
//     width: 20px;
//     background-color: blue;
//     text-align:center;
//     color: white;
// }
.ordered-list li {
    font-size: 16px;
}

.ordered-list li h3 {
    text-align: left;
    color: #337ab7;
}

.ordered-list {
    list-style: none;
    counter-reset: item;
}

.ordered-list li p {
    text-align: left;
}

.order-item {
    background-color: blue;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    padding: 5px;
    background: blue;
    border: 2px solid rgb(77, 14, 224);
    color: rgb(231, 217, 21);
    text-align: center; 
}

.what-section {

    background-image: url('../../../assets/sme-product/bg-03.png');
}

.what-section h1{
    color: #337ab7;
}

.what-section p {
    text-align: left;
    font-size: 20px;
    color:white;
    line-height: 30px;
}
.why-is-section h1{
    color: black;
}
.why-is-section p {
    text-align: left;
    font-size: 20px;
    color:#484a4d;
    line-height: 30px;
}
.why-is-section img {
    margin-top: 64px;
    width: 70%;
    height: auto;
}

.advantages-section {
    margin-top: 10px;
}

.advantages-section h1 {
    color: #337ab7;
    text-align: center;
}

.advantages-section .card {
    background-color:#0291f4 ;
}

.advantages-section h5 {
    color: #fcd107 !important;
    font-size: 24px;
    font-weight: bold;
    text-align: center !important;
    width: 249px;

}

.advantages-section .card-text{
    color: white;
    padding-left: 30%;
}
.advantages-section p {
    padding-left: 27%;
    color: white;
    width: 210px;
    font-size: 16px;
}

.benefit-section {
    margin-top: 10px;
}

.benefit-section h1 {
    color: #337ab7;
    text-align: center;
}

.option {
    background-color: #0291f4;
    color: white;
    font-weight: 600;
    width: 122px;
    border-radius: 10px;
    font-size: 20px;
    border-color: lightblue;
}

.options-tab {
    border-radius: 2%;
    background-color: #168fd05e;
    height: 285px;
}

.options-tab ul li {
    text-align: left;
    font-size: 16px;
    color: blue;
    background-color: #168fd000;
    border: none;
    border-bottom: solid;
    border-color: white;
    border-width: 1px;
}

.options-tab ul li:last-child{
    border: none;
    
}

@media (min-width: 260px) and (max-width: 760px) {
    .more-info{
     display: none;     
    }
    .get-quote {
        display: none;
    }
    .quote-form {
        margin-left: 33px;
    }
    .card-img-top {
        margin-top: 0%;
        width: 100px;
        height: auto;
    }

    .top-img {
        margin-top: 2%;
    }

    #sec-last-h1 {
        padding-top: 0;
        margin-top: 100% !important;
    }
    .options-tab {
        margin-right: 3%;
        padding-bottom: 2%;
        height: 400px;
    }

    .h5-sec-01 {
        margin-top: 100%;        
    }

    .caption-title {
        display: none;
    }

    .adv-sec-h1 {
        margin-top: -2%;
    }

    .adv-content {
        margin-top: 40%;
    }
}
