.cart-widget {
  position: fixed;
  right: 5px;
  z-index: 127;
  background: white;
  border: 1px solid;
  border-color: #d6cbcb;
  padding: 10px;
  border-radius: 7px;
  //top: 19%;
  left: auto !important;
  background-color: #bdc0c1;
  color: #fff;
  cursor: all-scroll;
  .cart-member-info{
    background: #929292;
    padding: 3px 5px 3px 5px;
    margin: 5px 0 0 0;
    width: 100%;
    .member-name {
      margin: 0;
      text-align: left;
      width: 35%;
      font-size: 12px;
      font-weight: bold;
    }
    .premium-info{
      width: 100%;
      margin: 0;
      .member-relation{
        padding-left: 5px;
        text-align: left;
      }
      .member-premium{
        float: right;
        padding-left: 8px;
        padding-right: 8px;
        text-align: left;
      }
    
    }
    .member-cover{
      text-align: right !important;
    }
  }

}
.member-name th {
  background-color: #929292 !important;
  width: 125px !important;
}

.cart-totle{
  background-color: #929292;
  padding: 5px;
}