#underline{
    color: #555;
    font-size: 22px;
    text-decoration: line-through;
    text-decoration-color: rgb(61, 58, 58);
    //margin: 4px 2px -9px 135px;
    color: #918d8d;
    text-align: center;
    overflow: auto;
    padding-top: 25px;
}

.popup-class{
    height: 107px;
}

.text-align{
    overflow: auto;
    padding-top: 25px;
    text-align: left;
    font-size: 16px;
}

.padding-manage{
    padding-top: 8px;
}

.modal-body{
    padding: 0px !important;
}

.hiddin{
    display: none;
}

.card{
    background: white;
    border-radius: 5px;
    padding: 10px;
    color: #000000;
    margin-right: -23px;
    margin-bottom: 7px;
}

.card-body{
    margin-right: 110px;
    font-weight: 500;
}

.bones-clear{
    flex: 1;
    flex-direction: "column";
    justify-Content: "center"
}
.nuclei-bottom-bones{
    margin-top:12px
}

.nuclei-bottom-bones:focus{
    background: #6bc259
}

.bankvalue{
    color: #000000;
}

.bankname{
    font-size: 11px !important;
    margin-top: 30px;
    float: left;
    font-weight: 600;
    overflow: -webkit-paged-x;
}

.bank-payment-header{
    margin-right: 232px;
    font-weight: 600;

}

.modal-footer{
    border-radius: 20px; 
}

.bank-img{
    height:75%;
    width:75%;
    margin-left:20px;
    margin-top: -4px;
}
.bank-img-list{
    height:100%;
    width:100%;
    margin-left:20px;
    margin-top: -4px;
    margin-left: 2px;
}

.Premium-head{
    font-weight: 600;
    color: #a5a5a5;
}

.card-value {
    margin-left: -5px;
    margin-top: 22px;
    font-size: 22px;
}

.nuclei-bottom-bones {
    margin-top: 20px;
}

.front-gray-body-popup{
    background: #fafafa;
    padding: 16px;
    border-radius: 6px;
    margin-top: 2px;
    color: #a5a5a5;
}


@media (max-width : 415px){
    .card-body {
        margin-right: 14px;
        font-weight: 500;
    }
    .hiddinx{
        display:none
    }
    
    .bank-img {
        margin-top: 19px;
    }
    .Premium-head {
        font-size: 12px
    }
    #underline {   
        font-size: 13px;
    }
    .bankname {
        font-size: 10px !important;
        margin-top: 0px;
    }
    .card-value{
        margin-left: -5px;
        font-size: 16px;
        margin-top: 0px;
    }
    .nuclei-bottom-bones {
        padding: 10px;
        margin-left: 5px;
        margin-top: -4px;
    }
    .as{
    display: block;
    margin-top: 83px;
   }
   .bank-payment-header {
    font-weight: 600;
    margin-right: 40px;
    font-size: 11px;
   }

  .discount-price {
    font-size: 14px;
   }
   .total-price {
    padding: 10px;
    font-size: 18px;
    margin-left: -12px
    }
}