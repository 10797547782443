@import "../variables";
.nuclei-header-title {
    text-align: left;
    padding-bottom:12pxx;
    h3 {
        color: $primary-blue;
        font-weight: 100;
        font-size:22px
    }
    .bold {
        font-weight: bold;
    }
    .subTitle {
        font-weight: 400;
        font-size: 12px;
        padding-left: 0px;
        margin-bottom: 0px;
        color: $primary-font-color;
    }
    .nuclei-header-title-icon{
        float: left;
        margin: -4px 5px 0px 0px;
    }
}