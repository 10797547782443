@import "../variables";
// red rounded long button
.rounded-long-btn-red {
    font-family: $font-family;
    -webkit-border-radius: 44;
    -moz-border-radius: 44;
    border-radius: 44px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-red;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-long-btn-red:hover {
    background: $primary-red;
    text-decoration: none;
}

//yellow rounded long button
.rounded-long-btn-yellow {
    font-family: $font-family;
    -webkit-border-radius: 44;
    -moz-border-radius: 44;
    border-radius: 44px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-yellow;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-long-btn-yellow:hover {
    background: $primary-yellow;
    text-decoration: none;
}

//blue rounded long button
.rounded-long-btn-blue {
    font-family: $font-family;
    -webkit-border-radius: 44;
    -moz-border-radius: 44;
    border-radius: 44px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-blue;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-long-btn-blue:hover {
    background: $primary-blue;
    text-decoration: none;
}

//red rounded reactangle button
.rounded-reactangle-btn-red {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-red;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-reactangle-btn-red:hover {
    background: $primary-red;
    text-decoration: none;
}

// yellow rounded reactangle button
.rounded-reactangle-btn-yellow {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-yellow;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-reactangle-btn-yellow:hover {
    background: $primary-yellow;
    text-decoration: none;
}

//blue rounded reactangle button
.rounded-reactangle-btn-blue {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-blue;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.rounded-reactangle-btn-blue:hover {
    background: $primary-blue;
    text-decoration: none;
}

//red reactangle button
.reactangle-btn-red {
    font-family: $font-family;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 30px;
    background: $primary-red;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.reactangle-btn-red:hover {
    background: $primary-red;
    text-decoration: none;
}

//yellow reactangle button
.reactangle-btn-yellow {
    font-family: $font-family;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-yellow;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.reactangle-btn-yellow:hover {
    background: $primary-yellow;
    text-decoration: none;
}

//blue reactangle button
.reactangle-btn-blue {
    font-family: $font-family;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    background: $primary-blue;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.reactangle-btn-blue:hover {
    background: $primary-blue;
    text-decoration: none;
}

//yellow smallRoundedReactangle button
.smallRoundedReactangle-btn-yellow {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #175073;
    font-size: 14px;
    background: #f9ffc3;
    padding: 10px;
    border: solid #839200 1px;
    text-decoration: none;
    border: none;
    border-radius: 4px;
    margin-left: 5px;
    color: rgb(216, 160, 160);
    font-weight: 400;
}

.smallRoundedReactangle-btn-yellow:hover {
    background: #f9ffc3;
    text-decoration: none;
}

//blue smallRounded reactangle button
.smallRoundedReactangle-btn-blue {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #175073;
    font-size: 14px;
    background: #c3e7ff;
    padding: 10px;
    border: solid #175073 1px;
    text-decoration: none;
    font-weight: 100;
    border: none;
    border-radius: 4px;
    font-weight: 400;
    color: rgb(107, 168, 238);
}


.smallRoundedReactangle-btn-blue:hover {
    background: #b3dcf7;
    text-decoration: none;
}

//red small Rounded reactangle button
.smallRoundedReactangle-btn-red {
    font-family: $font-family;
    -webkit-border-radius: 10;
    -moz-border-radius: 10;
    border-radius: 10px;
    font-family: $font-family;
    color: #175073;
    font-size: 14px;
    background: #ffc3c3;
    padding: 10px 20px 10px 20px;
    border: solid #a90000 1px;
    text-decoration: none;
}

.smallRoundedReactangle-btn-red:hover {
    background: #ffc3c3;
    text-decoration: none;
}

.btnSlicedRounded-yellow {
    font-family: $font-family;
    background: $primary-yellow;
    background-image: -webkit-linear-gradient(top, #fcd108, #fcd108);
    background-image: -moz-linear-gradient(top, #fcd108, #fcd108);
    background-image: -ms-linear-gradient(top, #fcd108, #fcd108);
    background-image: -o-linear-gradient(top, #fcd108, #fcd108);
    background-image: linear-gradient(to bottom, #fcd108, #fcd108);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 0px 0px 5px 5px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.btnSlicedRounded-yellow:hover {
    background: $primary-yellow;
    background-image: -webkit-linear-gradient(top, #fcd108, #fcd108);
    background-image: -moz-linear-gradient(top, #fcd108, #fcd108);
    background-image: -ms-linear-gradient(top, #fcd108, #fcd108);
    background-image: -o-linear-gradient(top, #fcd108, #fcd108);
    background-image: linear-gradient(to bottom, #fcd108, #fcd108);
    text-decoration: none;
}

.btnSlicedRounded-blue {
    font-family: $font-family;
    background: $primary-blue;
    background-image: -webkit-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -moz-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -ms-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -o-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: linear-gradient(to bottom, $primary-blue, $primary-blue);
    -webkit-border-radius: 5;
    -moz-border-radius: 5;
    border-radius: 0px 0px 5px 5px;
    font-family: $font-family;
    color: #ffffff;
    font-size: 20px;
    padding: 10px 20px 10px 20px;
    text-decoration: none;
    border: none;
}

.btnSlicedRounded-blue:hover {
    background: $primary-blue;
    background-image: -webkit-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -moz-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -ms-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: -o-linear-gradient(top, $primary-blue, $primary-blue);
    background-image: linear-gradient(to bottom, $primary-blue, $primary-blue);
    text-decoration: none;
}

#category-actions {
    font-family: "Open Sans", sans-serif;
    border-radius: 15px; //background: #f53280;
    padding: 20px;
    width: 150px;
    height: 150px;
    color: #fff;
    box-shadow: 5px -2px 10px #808080cf;
    margin: 5px 0px 0px 41px;
    img {
        margin: 10px 0px 10px 0px;
    }
}

#thumbail-cat {
    width: 275px;
}

.thumbnail {
    font-family: $font-family;
    display: block;
    padding-top: 26px;
    padding-bottom: 20px;
    margin-bottom: 20px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid white;
    border-radius: 4px;
    -webkit-transition: border .2s ease-in-out;
    -o-transition: border .2s ease-in-out;
    transition: border .2s ease-in-out;
    border-radius: 10px;
    box-shadow: 0px 0px 35px rgba(175, 173, 173, 0.21);
}

.thumbnail .caption {
    padding: 10px 0px 0px 15px;
    color: #333;
}


.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
    color: white;
    background-color: $primary-blue;
    border-color: #8c8c8c;
}



#quction-1 {
    font-family: $font-family;
    height: 124px;
    width: 150px;
    padding: 48px 10px 0px 10px;
    margin-right: 20px;
    border-radius: 5px;
    input {
        display: none;
    }
}

#quction-2 {
    height: 124px;
    font-family: $font-family;
    width: 150px;
    padding: 48px 10px 0px 10px;
    margin-right: 20px;
    border-radius: 5px;
    input {
        display: none;
    }
}

#format-4 {
    font-family: $font-family;
    height: 60px;
    width: 150px;
    padding: 21px 15px 15px 15px;
    margin-right: 20px;
    border-radius: 1ch;
}

#format-3 {
    font-family: $font-family;
    height: 60px;
    width: 150px;
    padding: 21px 15px 15px 15px;
    margin-right: 20px;
    border-radius: 1ch;
}

#format-2 {
    font-family: $font-family;
    height: 60px;
    width: 150px;
    padding: 21px 15px 15px 15px;
    margin-right: 20px;
    border-radius: 1ch;
}

#format-1 {
    font-family: $font-family;
    height: 60px;
    width: 150px;
    padding: 21px 15px 15px 15px;
    margin-right: 20px;
    border-radius: 1ch;
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
    color: white;
    background-color: $primary-blue;
    border-color: #adadad;
}

.carousel-indicators {
    width: 5%;
    bottom: 20%;
    margin-left: 44%;
    
    // -webkit-transform: rotate(90deg);
    // -webkit-transform-origin: right top;
    // -moz-transform: rotate(90deg);
    // -moz-transform-origin: right top;
    // -ms-transform: rotate(90deg);
    // -ms-transform-origin: right top;
    // -o-transform: rotate(90deg);
    // -o-transform-origin: right top;
    transform: rotate(90deg);
    transform-origin: right top;
    position: absolute;
    white-space: nowrap;
    top: 14vw;
    margin-right:10px;

}
.carousel-indicators li{
    margin: 6px;
    width: 7px;
    height: 7px;
    &.active{
        margin: 6px;
        width: 7px;
        height: 7px;
    }
}

#blog-caption-img{
    text-align: left;
    width: 100%;
    font-family: $font-family;
    padding: 0px 0px 15px 0px;
    margin: 0;
}

#blog-caption-img .caption h3{
    margin: 10px 0px 5px 0px;
}

#blog-caption-img .caption p{
    color: #727272;
}

#blog-caption-img .caption{
    padding: 10px 0px 0px 15px;
}

#blog-caption .caption .home-caption-label{
    padding: 3px;
    top: 0;
}

.user-details {
    position: relative;
    padding: 0;
}

.user-details .user-image {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
}

.user-image img {
    clear: both;
    margin: -60px 0px 0px -60px;
    position: absolute;
}

.user-details .user-info-block {
    width: 100%;
    top: 55px;
    background: #f9f9f9;
    z-index: 0;
    padding-top: 35px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
    p {
        font-size: 18px;
        color: #969696;
    }
}

.user-info-block .user-heading {
    width: 100%;
    text-align: center;
    margin: 10px 0 0;
    h4 {
        width: 100%;
        margin: 10px 0;
        color: #ec2a2b;
        padding: 5px 0px 0px 0px
    }
}

.user-info-block .navigation {
    float: left;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
    border-bottom: 1px solid #428BCA;
    border-top: 1px solid #428BCA;
}

.navigation li {
    float: left;
    margin: 0;
    padding: 0;
}

.navigation li a {
    padding: 20px 30px;
    float: left;
}

.navigation li.active a {
    background: #428BCA;
    color: #fff;
}

.user-info-block .user-body {
    float: left;
    padding: 5%;
    width: 90%;
}

.user-body .tab-content>div {
    float: left;
    width: 100%;
}

.pricing-table {
    max-width: 368px;
    margin: 0 auto;
    transition: all .3s ease;
    -o-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -webkit-transition: all .3s ease;
    border-radius: 0px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.06)
}

.pricing-table:hover {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .2);
}

.pricing-table:hover>.panel-footer-landing {
    box-shadow: 0px 0px 30px rgba(0, 0, 0, .05) inset;
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.panel-body-landing {
    // background: #5CB85C;
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.panel-heading-landing-box {
    background: #f0f0f0 !important;
    color: #333 !important;
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.controle-header {
    //  background: #5CB85C !important;
    /*    border: solid 2px #5CB85C !important;*/
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.panel-footer {
    //   background: #5CB85C !important;
    /*    border: solid 2px #5CB85C !important;*/
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.panel-footer>.btn {
    border: solid 1px #fff !important;
    -webkit-transition: all .3s ease;
}

.btn-price:hover {
    background-color: $primary-blue !important;
    color: rgb(64, 11, 255) !important;
    -webkit-transition: all .3s ease;
    font-family: $font-family;
}

.pricing-table:hover>.panel>.controle-header>.panel-title-landing {
    color: #000 !important;
    -webkit-transition: all .3s ease;
}

.pricing-table:hover>.panel>.panel-body-landing>.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    color: #000 !important;
    -webkit-transition: all .3s ease;
}
.pricing-table{
    .panel-primary>.panel-heading{
        text-align: center;
    }
    .panel-body {
        text-align: center;
    }
    .bt-buy-now{
        text-align: center;
    }
    .bt-buy-later{
        text-align: center;
    }
}
.widget{
    text-align: center;
    min-height: 256px;
}
.panel-heading-landing {
    background: #fff !important;
    padding: 20px !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    border: solid 2px #fff !important;
    border-bottom: none !important;
}

.panel-heading-landing-box {
    background: #ffffff !important;
    color: #fff !important;
    font-size: 16px !important;
    padding: 3px !important;
    border: solid 2px #fff !important;
    border-top: none !important;
}

.panel-title-landing {
    color: #626367 !important;
    font-size: 25px;
    font-weight: bold;
}

.panel-body-landing {
    // border: solid 2px #fff !important;
    border-top: none !important;
    border-bottom: none !important;
    padding-bottom: 8px;
}

.panel-footer-landing {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    border-top: none !important;
}


#buy-now {
    padding: 0px 0px 0px 0px;
    #btn-now {
        padding-left: 25px;
        padding-right: 25px;
        background-color: $primary-blue;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 4px;
        float: left;
    }
}

#buy-later {
    padding: 0px 0px 0px 0px;
    #btn-later {
        padding-left: 25px;
        padding-right: 25px;
        background-color: #0084dd;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 0px;
        float: right;
    }
}

#img {
    background: url(../assets/img2.png)no-repeat;
    padding: 19px 10px 20px 10px;
    margin-left: 70px;
}

#img-b {
    background: url(../assets/img1.png)no-repeat;
    padding: 19px 10px 20px 10px;
    margin-left: 70px;
}

#widgets-footer {
    background-color: #fff;
    margin: -1px;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c00;
    border-color: #ffffff00;
}

#paymentMethod {
    // width: 100%;
    // height: auto;
    width: 100%;
    /* max-height: 75px; */
    max-width: 140px;
    height: auto;
    position: absolute;
}

#paymentMethod-box-a {
    float: left;
}

#paymentMethod-box-b {
    float: right;
}

.paymentMethod {
    height: 100px;
    margin-bottom: 15px;
}

.list-group-item {
    position: relative;
    display: block;
    padding: 10px 15px; //  margin-bottom: -1px;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
    z-index: 2;
    color: #000;
    background-color: #dfdfdf;
    border-color: #dfdfdf;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #f14040;
}

.list-group-item .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
    color: #f14040;
}

#cameraIcon {
    background: #f9f9f9;
    font-size: 50px;
    border-radius: 10px;
    padding: 15px 0px 5px 0px;
}

#cameraIconSize {
    color: #e6e6e6;
    size: 25px;
}

#nav-header {
    background-color: #fff
}

#slider-container {
    padding: 0px 0px 0px 0px;
    margin: 0px -15px 0px -15px;
}

.carousel-inner {
    position: absolute;
    width: 100%;
    overflow: hidden;
}

.carousel {
    position: relative;
    height: 265px;
}


.widget:hover {
    cursor: pointer;
}

.widget {
    padding: 0px 9px;
    .widget-body-checked{
        outline: 0px solid transparent;
        border: 1px solid $primary-green;
        border-radius: 9px;
    }
    .widget-body{
        outline: 1px solid transparent;
        border: 1px solid transparent;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
        // margin-bottom: 18px;
    }
}


.vehicle-format label{
    color: #c1c1c1;
    font-weight: 700;
}

.zero-padding-right{
    padding-right: 0;
}

.zero-padding-left{
    padding-left: 0;
}

.zero-padding{
    padding: 0px;
}

// .carousel{
//     height: 300px;
// }

// .carousel-detail{
//     left: 0;
//     right: 0;
//     padding: 0px 15px 0px 15px;
// }

// .home-dock-panel{
//     background-color: #7c6f8e6b;
//     opacity: 0.8;
//     padding: 0px 0px 0px 0px;
//     height: 660px;
//     width: 660px;
//     border-radius: 10px;
//     top: -15px;
// }

// .home-dock-panel div img{
//     position: relative;
//     left: 20px;
//     top: 20px;
// }

// .home-dock-panel div{
//     padding: 0;
//     height: 220px;
// }

// .carousel-inner .item{
//     height: 700px;
//     margin-bottom: 1em;
//     background-image: url(../assets/Home.jpg);
//     background-repeat: no-repeat;
//     background-size: cover;
// }

// .carousel-header{
//     margin: 220px 0px 0px 0px;
// }

// .carousel-header-title{
//     position: relative;
//     top: 0px;
//     left: 0px;
// }

// .carousel-header-title h3{
//     background-color: #e02225;
//     padding: 10px;
// }

// .carousel-header-description{
//     position: relative;
//     left: 0px;
//     top: 0px;
//     font-size: 12px;
//     color: black;
//     font-weight: 400;
// }

// #buynow-button{
//     background: #ffd500;;
//     color: black;
//     font-weight: bold;
//     border-radius: 20px;
//     font-size: 12px;
//     padding: 10px 45px;
//     position: relative;
// }

// @media (max-width: 1366px) {
//     .carousel{
//         height: 120px;
//     }
//     .carousel-inner .item{
//         height: 490px;
//     }
//     .home-dock-panel{
//         height: 450px;
//         width: 440px;
//     }
//     .home-dock-panel div{
//         height: 160px;
//         margin-bottom: -20px;
//         margin-right: -20px;
//     }
//     .home-dock-panel div img{
//         height: 120px;
//         left: 25px;
//         top: 25px;
//     }
//     .carousel-header{
//         margin: 75px 0px 0px 136px;
//     }
//     .carousel-indicators {
//         top: 220px;
//     }
// }

// @media (max-width: 1260px) {
//     .carousel-header{
//         margin:71px 0px 0px 36px;
//     }

// }

// @media (max-width: 1024px) {
//     .carousel{
//         height: 50px;
//     }
//     .carousel-inner .item{
//         height: 400px;
//     }
//     .home-dock-panel{
//         height: 360px;
//         width: 350px;
//     }
//     .home-dock-panel div{
//         height: 130px;
//         margin-bottom: -18px;
//         margin-right: -10px;
//     }
//     .home-dock-panel div img{
//         height: 100px;
//         left: 14px;
//         top: 17px;
//     }
//     .carousel-header{
//         margin: 50px 0px 0px 50px;
//     }
//     .carousel-header-title h3{
//         font-size: 15px;
//         font-weight: 600;
//     }
//     .carousel-indicators {
//         top: 220px;
//     }
// }

// @media (max-width: 767px) {
//     .carousel{
//         height: 430px;
//     }
//     .carousel-inner .item{
//         background-image: url(../assets/HomeM.png);
//         height: 900px;
//     }
//     .carousel-detail{
//         top: 20px;
//     }
//     .home-dock-panel{
//         top: 120px;
//         height: 590px;
//         width: 100%;
//     }
//     .home-dock-panel div{
//         height: 200px;
//     }
//     .home-dock-panel div img{
//         height: auto;
//         width: 90%;
//         margin: 0px 0px 0px 0px;
//         // top: 28px;
//         // left: 40px;  
//         max-width: 175px;
//         max-height: 175px;      
//     }

//     .carousel-header {
//         margin: 0;
//     }
//     .carousel-header-title{
//         position: relative;
//         top: 0px;
//         left: 0px;
//     }

//     .carousel-header-title h3{
//         background-color: #e7001c;
//         margin: 40px 0px 10px 0px;
//         padding: 10px;
//         font-size: 15px;
//     }
    
//     .carousel-header-description{
//         position: relative;
//         left: 0px;
//         top: 0px;
//         font-size: 12px;
//     }

//     #blog-caption-img div img{
//         max-width: 100%;
//         padding-left: 0;
//     }
//     .carousel-indicators {
//         top: 220px;
//     }
// }

// @media (max-width: 800px) {
//     .carousel-header-title h3{
//         font-size: 14px;
//     }
// }

// @media (max-width: 400px) {
//     .carousel-inner .item{
//         height: 650px;
//     }
//     .carousel{
//         height: 340px;
//     }
//     .home-dock-panel{
//         top: 90px;
//         height: 340px;
//     }
//     .home-dock-panel div{
//         height: 125px;
//     }
//     .home-dock-panel div img{
//         left: 10px;
//         top: 10px;
//     }
    
// }

// @media (max-width: 980px) and (min-width: 768px) {
//     #blog-caption-img .caption div button{
//         font-size: 9px;
//     }
//     #blog-caption .caption div button{
//         font-size: 9px;
//     }
// }

.quick-tags{
    background-color: #f7f7f7;
    border-radius: 10px;
    text-align: left;
    padding: 30px;
    margin-top: 30px;
}

.quick-tag-header h2{
    color: $primary-blue;
    margin: 0;
}

.quick-tag-lists{
    padding-top: 10px;
    color: #686868;
}

.quick-tag-list{
    margin: 0;
    font-size: 18px;
}

// @media (max-width: 767px) and (min-width: 730px) {
//     .home-dock-panel div img{
//         left: 40px;    
//     }
// }

// @media (max-width: 729px) and (min-width: 680px) {
//     .home-dock-panel div img{
//         left: 30px;    
//     }
// }

// @media (max-width: 679px) and (min-width: 630px) {
//     .home-dock-panel div img{
//         left: 25px;    
//     }
//     .home-dock-panel{
//         height: 580px;
//     }
// }

// @media (max-width: 629px) and (min-width: 400px) {
//     .home-dock-panel{
//         height: 570px;
//     }
// }

// @media (max-width: 560px) and (min-width: 500px) {
//     .home-dock-panel{
//         height: 545px;
//     }
//     .home-dock-panel div{
//         height: 190px;
//     }
// }

// @media (max-width: 499px) and (min-width: 450px) {
//     .home-dock-panel{
//         height: 450px;
//     }
//     .home-dock-panel div{
//         height: 160px;
//     }
// }

// @media (max-width: 449px) and (min-width: 400px) {
//     .home-dock-panel{
//         height: 420px;
//     }
//     .home-dock-panel div{
//         height: 150px;
//     }
// }