@import "../../_variables.scss";

.modal-body.no-margin-row {
  .row {
    margin-left: 0;
    margin-right: 0;
  }

  button.request-otp{
    margin-top: 5px;
    border-radius: 5px;
    .no-margin-text {
      margin: 0;
    }

  }
}

// .navbar-wrapper{
//     padding-bottom: 14px;
// }
.justify-content-right {
  float: right;
}

.no-padding {
  padding: 0px !important;
}

.auth-nav-button {
  font-size: 17px;
  font-weight: 500;
}

.avatar {
  vertical-align: middle;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.justify-content-center {
  display: inline-block;
  margin: 0 auto;
  float: unset;
}

.centered-item {
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-auth {
  background: #fcd107;
  margin-bottom: unset;


  @media (max-width: 640px) {
    .container {
      .navbar-brand {
        display: none;
      }

      .justify-content-right {
        float: unset;
      }

      .nav > li {
        position: relative;
        display: inline-block;
      }
    }

  }


}

.navbar-no-margin {
  margin-bottom: unset;
}

.navbar-auth {
  background: #fcd107;
}

.nuclei-navbar {
  &.navbar-wrapper {
    padding-bottom: 0;
  }

  .navbar-toggle .icon-bar {
    background: white;
  }

  .navbar {
    background-color: white;
    border: none;
    // height: 100px;
  }

  .container-fluid {
    background-color: $primary-blue;
    height: 69.5px;
  }

  .navbar-brand {
    padding: 0;
  }

  .form-control {
    background-color: #2869bd;
    border: none;
    color: white;
    border-radius: 4px;
    min-width: 300px;

  }

  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: white;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: white;
  }

  #brand-logo {
    height: 100px;

    @media(max-width: 380px) {
      height: 101px;
      width: 80vw;
    }
  }

  #brand-logo-insu {
    height: 50px;
    margin-top: -73px;
    margin-left: 15px;

    @media(max-width: 380px) {
      height: 46px;
    }
  }

  .nuclei-header-img {
    width: 40px;
    height: auto;
  }

  .contact-icon a {
    padding: 13px 7px 0px 0px;

    &:focus {
      background-color: transparent;
    }

    &:hover {
      padding: 12px 6px 0px 0px;
      background-color: transparent;
    }
  }

  .contact-number a {
    color: #ffffff;
    text-decoration: none;

    &:focus {
      color: #ffffff;
    }

    &:hover {
      color: #ffffff;
    }

  }

  .contact-icon-text {
    color: #f9e900;
    padding: 26px 5px 0px 0px;
    font-size: 12px;
    margin: 0;
    text-align: right;
    line-height: 0;
  }

  .nuclei-search-bar {
    position: absolute;
    left: 34vw;
  }

  .nuclei-search-icon {
    padding: 7px 10px 9px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    color: white;
    text-align: left;
    background-color: #2869bd;
    border-radius: 4px 0px 0px 4px;
    position: absolute;
    left: -21px;
    top: 0;
  }

  .navbar-search {
    min-width: 18vw;
    left: 33vw;
    top: 15px;
    width: 27vw;
    height: 34px;
    padding: 18px 5px;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 0px 4px 4px 0px;
    @media(max-width: 767px) {
      z-index: 1;
      width: 100%;
      position: relative;
      left: 0;
      top: 0;
    }
  }

  @media(max-width: 768px) {
    .header-item > li {
      z-index: 1;
    }
  }

  .contact-number {
    color: white;
    padding: 4px 5px 0px 0px;
    text-align: right;
  }

  .nuclei-header-nav-bar {
    margin: 20px 0px 0px 0px;
    @media(max-width: 992px) {
      padding-left: 80px;
    }

    a {
      &:focus {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
        font-weight: 600;
      }
    }

    .nuclei-header-nav-text {
      padding: 0px 30px 0px 0px;
      color: black;
    }

    .active {
      font-weight: bold;
    }
  }

  .deactive {
    display: none;
  }
}
