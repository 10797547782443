.nuclei-slider-caption{
    padding-top: 5vw;
    padding-left: 40vw;
    .animated{
        animation-duration: 1.5s;
    }
}

.nuclei-slider-main-button{
    background: #e02225;
    font-size: 2vw;
}

.nuclei-slider-description{
    color: black;
    font-size: 1vw;
    padding-top: 2px;
    margin: 0;
}
.nuclei-slider-bottom-button{
    font-weight: bold;
}
.nuclier-yellow-bottom-button{
    padding-top: 2px;
}

@media (max-width: 767px) {    
    .nuclei-slider-main-button{
        font-size: 4.5vw;
    }
    
    .nuclei-slider-description{
        font-size: 3vw;
        padding-top: 1vw;
    }

    .nuclei-slider-bottom-button{
        font-size: 3vw;
    }
    .nuclei-slider-caption{
        padding-left: 0;
    }
}