// #nuclei-grid-tile{
//     background-color: #4b8dee;
//     width: 9.5vw;
//     height: 9.5vw;
//     margin: 1vw 0vw 0vw 1vw;
//     border-radius: 0.5vw;
//     position: relative;
// }
#nuclei-grid-tile {
    background-color: #4b8dee;
    width: 10.2vw;
    height: 9.7vw;
    margin: 0.9vw 0.8vw 0vw 0.7vw;
    border-radius: 0.5vw;
    position: relative;
}



.nuclei-grid-tile-top-text{
    padding-top: 1vw;
    font-size: 1.1vw;
    margin: 0;
}

.nuclei-grid-tile-img{
    width: 5.7vw;
    height: 4.5vw;
}

.nuclei-grid-tile-bottom-text{
    font-size: 1.1vw;
}

.grid-tile-link >a{
    text-decoration: none;
    color: white;
}

.grid-tile-link:hover {
    opacity: 0.8;
}

@media (max-width: 767px) {
    #nuclei-grid-tile{
        width: 24vw;
        height: 24vw;
        margin: 4.5vw 0vw 0vw 3.8vw;
        border-radius: 1vw;
    }
    
    .nuclei-grid-tile-top-text{
        padding-top: 2vw;
        font-size: 2.5vw;
    }
    
    .nuclei-grid-tile-img{
        width: 15.5vw;
        height: 14vw;
    }
    
    .nuclei-grid-tile-bottom-text{
        font-size: 2.5vw;
    }
}