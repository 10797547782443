.alert{
    font-weight: 300;
    padding-top: 10px;
    padding-bottom: 10px;
    font-family: sans-serif;
    border:none;
    margin-top: 10px;
}
.alert-danger{
    background-color: #f05858;
  }
  .alert-info{
    background-color: #0094f7;
  }
  .alert-warning{
    background-color: #fcd107;
  }
  .alert-success{
    background-color: #6ac259;
  }

  