.nuclei-jumbotron{
  background: rgb(247, 247, 247);
  min-height: 200px;
  margin-top: 10px;
  padding-top: 40px;
  .SubTitle {
    color: darkgray;
    font-size: 14px
  }
}

//Mobile View design
.form-control-mobile-jumbotron {
  margin-left: 28px;
  display: block;
  width: 84%;
  height: 34px;
  padding: 18px 44px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #bdbdbd;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  // -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  // box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
  // -webkit-transition: border-color ease-in-out .15s,-webkit-box-shadow ease-in-out .15s;
  // -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  // transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color:white;
  text-align: left;
  background-color:  #bdbdbd;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  display: block;
  position: absolute;
  margin-top: 61px;
  margin-left: 38px;
}

@media (max-width:767px){
.nuclei-jumbotron .SubTitle {
  color: darkgray;
  font-size: 14px;
  text-align: center;
  margin-top: 5px;
  padding: 0px 10px 0px 10px;
}

.nuclei-jumbotron {
  background: #f7f7f7;
  min-height: 130px;
  margin-top: 18px;
  padding-top: 40px;
}
}