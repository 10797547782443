.spinner {
    margin: 100px auto;
    width: 60px;
    height: 60px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 1.0s infinite linear;
    animation: sk-rotate 1.0s infinite linear;
    &.small{
      margin: 0 auto;
      width: 20px;
      height: 20px;
    }
}

.dot1,
.dot2 {
    width: 60%;
    height: 60%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: #0094f7;
    border-radius: 100%;
    -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
    animation: sk-bounce 2.0s infinite ease-in-out;
}

.dot2 {
    top: auto;
    bottom: 0;
    background-color: #f9d12b;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

.dot3 {
    width: 20%;
    height: 20%;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: red;
    border-radius: 100%;
    -webkit-animation: sk-bounce -1.5s infinite ease-in-out;
    animation: sk-bounce -1.5s infinite ease-in-out;
    -webkit-animation-delay: -1.0s;
    animation-delay: -1.0s;
}

@-webkit-keyframes sk-rotate {
    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes sk-rotate {
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg)
    }
}

@-webkit-keyframes sk-bounce {
    0%,
    100% {
        -webkit-transform: scale(0.0)
    }
    50% {
        -webkit-transform: scale(1.0)
    }
}

@keyframes sk-bounce {
    0%,
    100% {
        transform: scale(0.0);
        -webkit-transform: scale(0.0);
    }
    50% {
        transform: scale(1.0);
        -webkit-transform: scale(1.0);
    }
}