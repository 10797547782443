.header {
  cursor: pointer;
  display: block;
  min-width: 250px;
  max-width: 500px;
  // border: solid 1px #f2f2f2;
  // padding-top: 20px;
  // padding-bottom: 15px;
  font-family: sans-serif, "Helvetica Neue", "Lucida Grande";
  font-size: 20px;
  color: gray;

}

.tabs-content {
  // cursor: pointer;
  // border-radius: 0 0 5px 5px;
  //padding: 15px;
  // padding-left: 20px;
  font-family: verdana;

}

.triangle-right {
  width: 0;
  height: 0;
  border-top: 25px solid transparent;
  border-left: 50px solid #555;
  border-bottom: 25px solid transparent;
}
