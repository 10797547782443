.nuclei-collapse-content {
  color: Black;
  font-size: 15px;
  &:hover {
  color: black;
  text-decoration: none;
  }
}

.nuclei-collapse {
  margin-bottom: 12px;
  text-align: left;
}

.nuclei-collapse-body {
  padding: 15px;
  text-align: left;
}

.nuclei-collapse-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-radius: 5px 5px 5px 5px;
  text-align: left;
}
.nuclei-collapse-arrow {
  float: right;
  padding: 4px 0px 0px 0px;
  margin-right: -20px
}

@media(max-width:992px){
  .nuceli-sub-collapse
  {
      margin-top: 19px;
      background: #0094f6;
      color: white;
      margin-left: 11vw;
      padding: 1vw;
      font-size: 2vw;
      border-radius: 30px;
  }
  }
  

@media (max-width:767px){
  .nuclei-collapse-content {
    font-size: 2.8vw;
    }
    // .nuceli-sub-editdetails{
    //   background:red
    // }
  }


