@import "react-alice-carousel/lib/scss/alice-carousel.scss";
#nuclei-home{
    padding: 15px 0px 0px 0px;
}
.nuclei-feature-card-header{
    padding-top: 216px;
}
@media(max-width:480px){
    .nuclei-feature-card-header{
        padding-top: 428px;;
    }
}


.nuclei-slider-caption a .button-red{
    display: none;
}