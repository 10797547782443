.nuclei-blog-image-list{
    margin-top: 15px;
}

.nuclei-blog-image-list-img{
    max-height: 250px;
    max-width: 600px;
    min-height: 105px;
    @media(max-width: 768px){
        max-height: none;
        max-width: none;
    }
}