#home-carousel{
    height: 38vw;
    // background: red;
    background: linear-gradient(141deg, #736ec4 0%, #8dc5f6 51%, #9589d3 100%) ;
}

#nuclei-slider-container{
    margin: 0px -15px 0px -15px;
    padding: 0;
}

#nuclei-slider-container .carousel-inner .item{
    height: 38vw;
    margin-bottom: 1em;
    background-repeat: no-repeat;
    background-size: cover;
}

.nuclei-carousel-detail{
    position: relative;
    left: 0;
    top: 5px;
}

// media queries

@media only screen and (max-width: 600px) {
    #nuclei-slider-container .carousel-inner .item{
        height: 100vw;
    }
}


@media (max-width: 767px) {
    #home-carousel{
        height: 146vw;
    }

    .nuclei-carousel-detail{
        position: absolute;
        width: 100%;
    }
    
    #nuclei-slider-container .carousel-inner .item{
        height: 146vw;
        margin-bottom: 1em;
        background-repeat: no-repeat;
        background-size: cover;                         
    }
}



#carosal-left{
    display: none;
}

#carosal-right{
    display: none;
}