#headStyle {
    float: left;
    margin-top: 25px;
    
}

#sign {
    float: left;
    color: #c8c8c8
}

#content {
    text-align: left
}

#Text {
    font-weight: bold;
    text-align: left
}

#socialIcon {
    float: left;
}

#title {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
    h2 {
        color: #0094f7;
        span {
            font-weight: bold;
        }
    }
}

#imge {
    margin-bottom: 5px;
    border-radius: 5px
}

#imgeR {
    width: 100%;
    margin-bottom: 5px;
    border-radius: 5px 5px 0px 0px;
}



.email-input{
    border-radius: 5px 0px 0px 5px;
    height: 50px;
}

.bt-subscribe{
    border-radius: 0px 5px 5px 0px;
    background-color: #3093f8;
    width: 100%;
    height: 60px;
    color: white
}

.share-social{
    text-align: left;
    padding-left: 15px;
}

.share-social h2{
    font-weight: bold;
}

.share-social img{
    height: auto;
    width: 100%;
    float: left;
}

#ads{
    padding-top: 20px;
}







.nuclei-image-main{
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}

.nuclei-image-sub{
    width:Auto;
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}
.nuclei-image-sub-one{
    margin-top:18px;
    width:100%;
    border-radius: 8px;
}

 @media (max-width: 767px){
    .quick-tag-list-left{
        padding-left: 5px;
        float: right;
    }
    .quick-tag-list-right{
        padding-right: 5px;
    }
    .quick-tags{
        padding: 15px;
    }
    .nuclei-image-sub-one{
        margin-top:8px;
        width:100%;
        border-radius: 3px;
    }
 }


 @media (max-width: 576px) { 
    .nuclei-image-main{
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    
    .nuclei-image-sub{
        width:Auto;
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    .nuclei-image-sub-one{
        margin-top:5px;
        width:100%;
        border-radius: 3px;
    }

 }