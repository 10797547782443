@import "../variables";
.full-widget-button {
    padding-left: 25px;
    padding-right: 25px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 4px;
    width: 100%;
    height: 100%;
    border: none;
    padding: 15px 0;
    font-weight: 400;
    font-size: 13px;
    background-color: $primary-blue;
    color: #fff;
    text-decoration: none !important;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    border-radius: 0px 0px 8px 8px;
    font-family: $font-family;
    &:focus{
        outline:none;
    color: #fff;

    }
    &:hover{
        background-color: $primary-dark-blue;
        font-weight: 500;
    color: #fff;

    }
}

.price{
    font-weight: 400;
    font-size: 15px;
    margin-top: 0;
    color: $primary-font-color;
    font-family: $font-family;
}
.checkbox-container{
    min-height: 24px;
    position: relative;
    color: $primary-font-color;
}
// .widget{
//     height: 270px;
// }

.discount-price{
    display: block;
    color: #333333 !important;
    font-weight: 500;
    font-size: 18px;
    margin-top: -10px;
}
.promo-bank-label{
    font-size: 12px;
}
.without-discount{
    color: #c0c0c0;
    font-size: 14px;
    font-weight: 400;
    text-decoration: line-through ;
    text-decoration-color:#cc0000;
    padding-top: 2px;
}

.total-price{
    padding: 12px;
    font-size: 18px;
}
.pricing-table .panel-body{
    padding-top: 17px;

}