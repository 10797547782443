
 .nuclei-breadcrumb .breadcrumb-item a 
{
    padding-right: 5px;
    ;
}
.breadcrumb-item .active {
    font-weight: 500;
    color: #58aefb;
}
 .nuclei-breadcrumb .breadcrumb-item a {
    padding-right: 5px;
}
.breadcrumb-item svg{
    font-size: 10px;
    color:#1e201d !important
}
.breadcrumb-item{
    display: inline-block;
    margin-top: -10px;
    margin-left: 15px;
    position: relative;
}

   .breadcrumb-item a{
              padding-right: 5px;
              color: #999999;
              &:hover{
                  text-decoration: none;  
              }
              &:focus{
                  text-decoration: none;  
              }
              &:active{
                color: #58aefb !important;  
            }
              &:visited{
                color:#666666;
                  &:hover{
                      color: #58aefb;
                  }
                
              }
          }
          