
#nuclei-multiple-carousel {
  height: 17vw;
  .alice-carousel__wrapper {
    border: none;
  }

  .alice-carousel__stage-item{
    right: 17vw;
  }
}

// media query
// desktop and large
// @media (min-width: 1200px) {
//   .alice-carousel__stage-item{
//     left: 0;
//   }
// }
// ipad pro
// @media only screen and (min-width: 992px) {
//   .alice-carousel__stage-item{
//     left: 0;
//   }
// }

@media (max-width: 600px){
  #nuclei-multiple-carousel {
    height: 26vw;
  }
}

  @media (max-width: 768px){
    .alice-carousel__stage-item{
      left: 80px; 
    }
    .alice-carousel__prev-btn, .alice-carousel__next-btn {
      display: none;
    }
  }  
  @media (max-width: 1024px){
    .alice-carousel__prev-btn-item:hover,
    .alice-carousel__next-btn-item:hover {
      top: 1vw !important;
    }
  }

  .alice-carousel {
    padding: 0;
    position: absolute;
    left: 0;
  }

  .ads-carousel {
    padding: 10px;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn {
    top: 7vw;
  }

  .alice-carousel__prev-btn-item,
  .alice-carousel__next-btn-item {
    color: #272423;
    border: none;
    font-size: 5vw;
    font-weight: 500;
  }

  .alice-carousel__prev-btn-item:hover,
  .alice-carousel__next-btn-item:hover {
    // border: none;
    // color: #272423;
    // opacity: 0.8;
    border: none;
    color: white;
    opacity: 0.8;
    font-size: 5vw;
    top: -1vw;
  }

  .alice-carousel__prev-btn,
  .alice-carousel__next-btn{
    display: none !important;
  }