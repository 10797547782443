@import '../../../variables';
.membership-pricing-table {
    padding-top: 25px;
    padding-bottom: 70px;
    overflow: auto;
}

.membership-pricing-table table .icon-no,.membership-pricing-table table .icon-yes {
    font-size: 22px
}

.membership-pricing-table table .icon-no {
    color: #a93717
}

.membership-pricing-table table .icon-yes {
    color: #019ffa;
}

.membership-pricing-table table .plan-header {
    text-align: center;
    font-size: 48px;
    border: 1px solid #e2e2e2;
    // box-shadow: 0px 0px 35px rgba(175, 173, 173, 0.21);
    // padding: 25px 0
}

.membership-pricing-table table .plan-header-free {
    color: #555;
    border-radius: 0px 0px 0px 0px;
}

.membership-pricing-table table .plan-header-blue {
    color: #fff;
    background-color: #61a1d1;
    border-color: #3989c6
}

.membership-pricing-table table .plan-header-standard {
    color: #fff;
    background-color: #ff9317;
    border-color: #e37900
}

.membership-pricing-table table td,th {
    text-align: center;
    width: 15%;
    padding: 7px 10px;
    background-color: #fafafa;
    font-size: 14px;
    -webkit-box-shadow: 0 1px 0 #fff inset;
    box-shadow: 0 1px 0 #fff inset;
    padding: 0;
}

.membership-pricing-table table,.membership-pricing-table table td {
    border: 1px solid #ebebeb
}

.membership-pricing-table table tr td:first-child {
    // background-color: transparent;
    text-align: right;
    width: 24%
}

.membership-pricing-table table tr td:nth-child(5) {
    background-color: #FFF
}

.membership-pricing-table table tr:first-child td,.membership-pricing-table table tr:nth-child(2) td {
    -webkit-box-shadow: none;
    box-shadow: none
}

.membership-pricing-table table tr:first-child th:first-child {
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: #e2e2e2
}

.membership-pricing-table table tr:first-child th .pricing-plan-name {
    font-size: 22px
}

.membership-pricing-table table tr:first-child th .img {
    line-height: 35px;
    padding-left: 40px;
    padding-right: 40px;
}

.membership-pricing-table table tr:first-child th .img>sup {
    font-size: 45%
}

.membership-pricing-table table tr:first-child th .img>span {
    font-size: 30%
}

.membership-pricing-table table tr:first-child th .pricing {
    margin-top: 10px;
    font-size: 31%;
    color: #4eaff6;
    opacity: 0.9;
    font-weight: 500;
}

.membership-pricing-table table .header-plan-inner {
    position: relative
}

.membership-pricing-table table .recommended-plan-ribbon {
    box-sizing: content-box;
    background-color: #dc3b5d;
    color: #FFF;
    position: absolute;
    padding: 3px 6px;
    font-size: 11px!important;
    font-weight: 500;
    left: -6px;
    top: -22px;
    z-index: 99;
    width: 100%;
    // -webkit-box-shadow: 0 -1px #c2284c inset;
    // box-shadow: 0 -1px #c2284c inset;
    // text-shadow: 0 -1px #c2284c
}

.membership-pricing-table table .recommended-plan-ribbon:before {
    border: solid;
    border-color: #c2284c transparent;
    border-width: 6px 0 0 6px;
    bottom: -5px;
    content: "";
    left: 0;
    position: absolute;
    z-index: 90
}

.membership-pricing-table table .recommended-plan-ribbon:after {
    border: solid;
    border-color: #c2284c transparent;
    border-width: 6px 6px 0 0;
    bottom: -5px;
    content: "";
    right: 0;
    position: absolute;
    z-index: 90
}

.membership-pricing-table table .plan-head {
    box-sizing: content-box;
    background-color: #ff9c00;
    border: 1px solid #cf7300;
    position: absolute;
    top: -33px;
    left: -1px;
    height: 30px;
    width: 100%;
    border-bottom: none;        
}
.btn-buy-now{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    width: 100%;
    height: auto;
    border: none;
    @media(max-width: 992px){
        font-size: 12px;
       
    }
    @media(max-width: 768px){
        font-size: 12px;
        border-radius: 4px;
      
    }
}
.btn-buy-later{
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 0px;
    width: 100%;
    height: auto;
    border: none;
    @media(max-width: 992px){
        font-size: 12px;
        padding-left: 8px
    }
    @media(max-width: 768px){
        font-size: 12px;
        margin-top: 5px;
        border-radius: 4px;
        text-align: center
    }
}

#btn-td div{
    padding:0;
}
a {
    color: #0094f6;
    text-decoration: none;
}
#fontcolor{
    color: #555
}

@media (max-width: 2560px){

.membership-pricing-table table tr td:first-child {
    // background-color: transparent;
    text-align: left;
    width: 24%;
    
}

.btn-buy-now {

    background-color: #049bff;
    //color: #ffffff;

}



.btn-buy-later {
    
    background-color: #006cca;
    //color: #ffffff;
}




.membership-pricing-table table, .membership-pricing-table table td {
    border: none;
}

.membership-pricing-table table .plan-header {
    
    border: 0px solid #e2e2e2;
    
}


    .sub-button-size{
        font-size: 12px
    }



}

.pricing-plan-header-img img{
    width: auto;
    height: 130px;
    padding-top: 35px;
}

.header-white{
    background-color: white;
}

.table-card-header{
    background-color: white;
    // border-radius: 10px 10px 0px 0px;
    // height: 180px;
    box-shadow: 0px 4px 5px 6px #f8f8f8;
    // margin-bottom: -7px;
}

.icon-text{
    font-size: 11px;
    color: #bebcbe;
    margin-top: 2px;
    cursor: pointer;

}

.icon-heder{
    display: flex;
    
}

.icon-img{
    margin-left: 5px;
    cursor: pointer;
}

.bg-style{
    background-color: white;
    padding: 2px;
    height: 50px;
    padding-top: 10px;
}

.bg-white-row{
    background-color: white !important;
}
.membership-pricing-table table td th {
    width: 23%; 
}
.membership-pricing-table table td {
    padding: 10px;
}

@media (max-width: 400px) {
    .pricing-plan-header-img img {
        width: 50px;
        padding-top: 10px;
    }
    .membership-pricing-table table tr:first-child th .pricing {
        margin-top: 10px;
        font-size: 20%;
        color: #4eaff6;
        opacity: 0.9;
        font-weight: 500;
    }
    .table-card-header {
        // background-color: white;
        // border-radius: 0px 0px 0px 0px;
        // height: 181px;
        box-shadow: 0px -8px 0px 10px #f8f8f8;
        // margin-bottom: -61px;
        // margin-left: -4px;
        // margin-right: -1px;
        // padding: -5px 9px 10px 10px;
    }
    .icon-text {
        font-size: 7px;
        color: #bebcbe;
        margin-top: 2px;
        text-align: left;
    }

    .bg-style {
        background-color: white;
        padding: 15px 29px 0px 0px;
        height: 65px;
        padding-top: 6px;
    }
    .membership-pricing-table table tr td:first-child {
        text-align: left;
        width: 24%;
        background: white;
    }
    .membership-pricing-table table td th {
        font-size: 10px;
     
    }
    .membership-pricing-table table .plan-header {
        text-align: center;
        font-size: 57px;
        //border: 1px solid #e2e2e2;
    }
    .glyphicon-remove:before {
        content: "\e014";
        font-size: 15px;
    }
    .glyphicon-ok:before {
        content: "\e013";
        font-size: 15px;
    }

    .bg-stylebox{
        text-align: center
    }

    .membership-pricing-table table td th {
        text-align: center;
        width: 33%;
        padding: 7px 10px;
        background-color: white;
        font-size: 13px;
        -webkit-box-shadow: 0 1px 0 #fff inset;
        /* box-shadow: 0 1px 0 #5f3535 inset; */
        box-shadow: 7px 1px 1px 0px #fafafa;
    }

    .btn-buy-now {
  
    font-size: 10px;
  }

  .btn-buy-later {
  
    font-size: 10px;
  }

  #sideboard{
      border:0px 0px 10px 0px red
  }
    

}

.nuclei-table-card-header{
    background-color: #ffffff;
    border-radius: 5px 5px 0 0;
    box-shadow: 0px 0px 19px 3px #eae9e9;
}

.nuclei-icon-heder{
    display: flex;
}

.nuclei-icon-img{
    margin-left: 5px;
    margin-top: 3px;
    cursor: pointer;
}

.nuclei-icon-text{
    font-size: 10px;
    color: #bebcbe;
    margin-top: 2px;
    font-weight: 300;
    padding-left: 6px;
    cursor: pointer;
}


.nuclei-model{
    width: 300px;
    @media(max-width: 768px){
        width: auto;
        padding: 15vw;
    }
}

.nuclei-model-footer{
    padding: 0;
}

.nuclei-footer-button{
    width: 100%;
    background-color:#f3f3f2;
    color: #a1a1a1; 
}

.nuclei-modal-content{
    padding-top: 25px;
    p{
        font-weight: 100;
        text-align: left;
        padding: 0px 0px 0px 25px;
    }
    
}


.nuclei-pricing-plan-header-img{
    padding: 40px 0;
    padding-top: 10px;
    img{
        height: 75px;
    }
    @media(max-width: 768px){
        padding: 20px 0;
        img{
            height: 44px;
        }
    }
}

.nuclei-pricing{
    padding-top: 10px;
    padding-bottom: 40px;
    font-weight: 300;
    font-family: $font-family;
    font-size: 17px;
    color: $primary_blue;
    @media(max-width: 768px){
        font-size: 10px;
        padding-bottom: 10px;
    }
}

.nuclei-plan-header-free{
    margin: 10px 10px;
    padding: 23px 7px 0;
    background: white;
}

.company-option{
    color: $primary_blue;
    font-weight: 300;
    font-family: $font-family;
    text-align: center;
    font-size: 14px;
    padding: 3px 10px;
    &:hover{
        font-weight: 400;
        cursor: pointer;
    }
    img{
        height: 40px;
    }
}