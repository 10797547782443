@import "../../variables";

.img-responsive{
    max-width: 600px;
    margin: "0 auto";
    display: block;
    height: auto;
    width: 100%;
}


.result-page-image-container{
    position: relative;
    display: inline-block;
}
.result-page-image-title{
    position: absolute;
    right: 14%;
    top: 12%;
    font-size: 23px;
    color: $primary-green;
    display: inline-block;
}
.custom-result-button{
    min-width: 200px;
    border-radius: 4px;
    font-family: $font-family;
    padding: 10px 28px;
    border: none;
    background:$primary-red;
    color: white;
    font-weight: 300;
    margin: 2px;
    font-size: 13px;
    &:focus,
    &:hover{
        outline: none;
        background: lighten($primary-red, 10%);
        text-decoration: none;
        color: white;
    }
}
.custom-description{
    margin: 10px;
    font-size: 12px;
};


@media (max-width: 576px) {
    .result-page-image-title{
        position: absolute;
        right: 10%;
        top: 12%;
        font-size: 16px;
        color: $primary-green;
        display: inline-block;
    }
}