@import "../variables";

@mixin nuclei-badge-color-mixin($color) {
    background-color: $color;
    &.badge-outline{
        background: lighten($color, 45%);
        color: $color;
        padding: 5px 12px 3px 12px;
        font-weight: 400;
        &.badge-yellow{
            background-color:  lighten($color, 30%);
            color:  darken($color, 18%);
        }
    }
  }

.nuclei-badge {
    padding: 10px 28px;
    border: none;
    background: #0094f7;
    color: white;
    font-weight: 300;
    margin: 2px;
    font-size: 12px;
}

.badge-blue{
    @include nuclei-badge-color-mixin($primary-blue);
}

.badge-red{
    @include nuclei-badge-color-mixin($primary-red);
}

.badge-yellow{
    @include nuclei-badge-color-mixin($primary-yellow);
    color: black;
}

.badge-radius{
    border-radius: 4px;
}

.badge-rounded{
    border-radius: 20px;
}

.badge-sliced{
    border-radius: 0 0 4px 4px;
}

.badge-small{
    // padding: 2px 4px 0.2px 4px;
    // font-size: 8px;
    // border-radius: 2px;
    padding: 3px 6px;
    font-size: 9px;
    border-radius: 2px;
    font-family: $font-family;
}