@-moz-document url-prefix() {
    @supports (animation: calc(0s)) {
        .popover{
            max-width:467px;
        }
    
    }
}
.h{
    display: none
}
.h1{
    display: none
}

.thank-you{
    text-align: center;
    margin-bottom: -25px;
    margin-top: -14px;
}

@supports (-ms-ime-align: auto) {
    .popover{
        max-width:467px;
    }
  }


.footer-dock-panel{
    .dock-panel-background{
        position: fixed;
        bottom: 0px;
        right: 0;
        width: 571.5px;
        z-index: 12;
    }
    .dock-panel-insureme-icon{
        position: fixed;
        bottom: 36px;
        right: 12px;
        z-index: 12;
        max-width: 110px;
    }
    .dock-panel-insureme-chat{
        position: fixed;
        bottom: 15px;
        right: 180px;
        z-index: 12;
        width: 57.14px;
        margin-right: -35px;
    }
    .dock-panel-insureme-call{
        position: fixed;
        bottom: 15px;
        right: 250px;
        z-index: 12;
        width: 57.14px;
        margin-right: -23px;
    }
    .dock-panel-insureme-quote{
        position: fixed;
        bottom: 15px;
        right: 320px;
        z-index: 12;
        width: 57.14px;
        margin-right: -10px;
    }
}


.footer-dock-panel-get-quote{
    width: 467px;
    max-width: 100%;
    max-width: -moz-available;          /* WebKit-based browsers will ignore this. */
    max-width: -webkit-fill-available;  /* Mozilla-based browsers will ignore this. */
    max-width: fill-available;
    // bottom: 100px !important;
    top: auto !important;
    bottom: 123px !important;
    position: fixed;
    .popover-content{
        text-align: center;
        padding: 0px 1.5vw 16px 22px;
    }  
    #nuclei-grid-tile{
        width: 130px;
        height: 130px;
    }  
    .nuclei-grid-tile-top-text{
        font-size: 12px;
    }
    .nuclei-grid-tile-bottom-text{
        font-size: 12px;
    }
    .nuclei-grid-tile-img{
        width: 83px;
        height: auto;
    }
}

.dock-panel-insureme-text{
    margin-top: 12px;
    margin-bottom:5px
}

.dock-panel-insureme-image{
    width: 57.14px;
    // &:hover{
    //     padding: 5px
    // }
}

.footer-dock-panel-call-back{
    padding: 12px 16px;
    top: auto !important;
    bottom: 123px !important;
    position: fixed;
}

.display-flex{
    display:flex
}

.dock-panel-hover{
    &:hover{
        cursor: pointer;
        padding-bottom: 5px
    }
}

#intercom-container .intercom-launcher-frame {
    bottom: 48px !important;
    right: 140px !important;
    background-image: url('../../assets/dock2.png');
    
}
@media only screen and (max-width: 768px) {
    #intercom-container .intercom-launcher-frame {
        bottom: 20px !important;
        right: 25px !important;
        background-image: url('../../assets/dock2.png');
        
    }
  }

@media only screen and (min-width: 768px) {
    .intercom-lightweight-app-launcher {
        margin-right: 124px;
        margin-bottom: 25px;
    }
}  
.mobileSection1{
    width: 20%;
    float: left;
}
.mobileSection2{
    width: 80%;
    float: right;
}
.mobileSection{
    width: 194px;
}

.dock-panel-best-web-vote {

    display: block;
    z-index: 12;
    position: fixed;
    bottom:10px;
    right:375px;
}

.ant-modal{
    position: absolute!important;
    bottom: 20%!important;
    right: 5%;
    top:initial!important;
}

.ant-modal-body{
    padding: 10px 36px 24px 24px!important;
}