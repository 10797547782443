#headStyle {
    float: left;
    margin-top: 25px
}

#sign {
    float: left;
    color: #c8c8c8
}


#Text {
    font-weight: bold;
    text-align: left
}

#socialIcon {
    float: left;
}

#facebook {
    width: 50px;
    height: 50px;
}

#twitter {
    width: 50px;
    height: 50px;
}

.blog-post-lable{
    padding: 3px;
    background-color: #e7001c;
    left: 15px;
    position: absolute;
}

.nuclei-blog-img{
    padding-bottom: 15px;
    .nuclei-blog-image-list-img {
        max-height: 100%;
        max-width: 100%; 
        min-height: auto; 
    }
}

.nuclei-image-top{
    margin-top: 20px;
    width: 100%
}


.content {
    text-align: left
}