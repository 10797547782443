.nuclei-blogImage {
    width: 100%;
    height: auto;
}
.blogImage-radius{
    border-radius: 4px;
}

.blogImage-rounded{
    border-radius: 50%;
}

.blogImage-sliced{
    border-radius: 0 0 4px 4px;
}

.blogImage-small{
    padding: 4px 14px;
    font-size: 8px;
    border-radius: 2px;
}