@import 'variables';

* {
  font-family: $font-family;
}

body {
  margin: 0;
  padding: 0;
  font-family: $font-family;
  font-weight: 300;
  padding: 0;
  margin: 0;
  text-align: center;
}

.carousel-caption {
  position: inherit;
  text-shadow: none;
}

.nuclei-input-label {
  display: block;
  color: $form-label;
  font-style: $font-family;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 0px !important;
  margin-top: 5px !important;
  margin-left: 2px !important;
}

#best-deal-icon-container {
  position: relative;
  overflow: auto;
}

#best-deal-icon {
  position: relative;
  background: #ff3372;
  padding: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  margin-top: -26px;
}

#without-agent-icon {
  background: #cc0000;
  padding: 4px;
  color: #ffffff;
  font-size: 12px;
  font-weight: 600;
  border-radius: 5px;
  float: left;
  margin-top: 4px;
}

@media(max-width: 415px) {
  #best-deal-icon {
    padding: 4px;
    font-size: 7px;
    float: left;
    margin-top: -28px;
    margin-left: -12px;
  }
  #without-agent-icon {
    padding: 4px;
    font-size: 7px;
    float: left;
    margin-top: -6px;
    margin-left: -12px;
  }

}

.css-1ep9fjw {
  padding-left: 0;
  position: absolute;
  right: -3px;
}

.css-1sjym57 {
  display: none;
}
