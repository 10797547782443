$primary-red:#e02225;
$primary-yellow:#fcd107;
$primary-blue:#0094f7;
$primary-dark-blue:#0084dd;
$primary-font-color: #333333;
$primary-green: #6ac259;
$lightgray-font-color:#c0c0c0;
$lightgray-gray-color:#ffffff;


// custom 
$checkbox-blue : #2292d0;
$gray-background: #fafafa;
$form-label: #009ef5;
$graylight-background:#f9f9f9;


// font family
$font-family: "Helvetica Neue", "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;
