.dashboard-container {
  /*
    DEMO STYLE
*/
  @import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

  a, a:hover, a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
  }


  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
  }

  /* ---------------------------------------------------
      SIDEBAR STYLE
  ----------------------------------------------------- */

  .wrapper {
    //display: flex;
    width: 100%;

    .slide-menu-handler {
      .glyphicon-menu-hamburger.active::before {
        content: "\e257"
      }

      position: fixed;
      background: #0294f7;
      border-radius: 10px;
      padding: 11px 11px 11px 50px;
      left: -35px;
      color: #fff;
      font-size: 15px;
      z-index: 9;
      display: none;
    }


  }

  .navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
  }

  .navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
  }

  #sidebar {
    max-width: 1150px;
    min-width: 150px;
    transition: all 0.3s;
    margin-top: 20px;
    font-size: 20px;
    //margin-left: -550px;

    ul li a:hover {
      //padding-left: 10px;
      //padding-right: 10px;
      padding-bottom: 10px;
      border-bottom: solid #0294f7;
      color: #0294f7;
      background: #fff;
    }

    .dropdown-toggle {
      .glyphicon-chevron-down {
        position: absolute;
        //right: 15px;
        //top: 15px;
        color: inherit;
      }
    }

    .dropdown-toggle[aria-expanded="true"] {
      .glyphicon-chevron-down::before {
        content: "\e113" !important;
      }
    }

    .dropdown-toggle::after {
      display: block;
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .components li {
      display: inline;
      margin: 0 20px;
      //padding-left: 10px;
      //padding-right: 10px;
      padding-bottom: 10px;
      border-bottom: solid #bfbbbb;
    }

    ul.sub-menu-level-1 {
      position: absolute;
    }

    ul.sub-menu-level-1 a {
      display: block;
    }

    ul li.active > a, .sub-menu a.active {
      color: #0294f7;
      //padding-left: 10px;
      //padding-right: 10px;
      padding-bottom: 10px;
      border-bottom: solid #0294f7;
    }

    ul.sub-menu-level-1 {
      padding: 0 0 0 180px;
      z-index: 1;
    }

    ul.CTAs {
      padding: 20px;
    }

    ul.CTAs a {
      text-align: center;
      font-size: 0.9em !important;
      display: block;
      border-radius: 5px;
      margin-bottom: 5px;
    }

    a.download {
      background: #fff;
      color: #7386D5;
    }

    a.article, a.article:hover {
      background: #6d7fcc !important;
      color: #fff !important;
    }


  }


  a[data-toggle="collapse"] {
    position: relative;
  }


  #content {
    //width: 100%;
    padding: 50px;
    //overflow: scroll;
    //position: relative;

    .profile-pic {
      .avatar-container {
        position: relative;
        width: 200px;
        margin: auto;
        float: left;
        padding-top: 50px;
      }

      .profile-header {
        position: absolute;
        padding: 20px 20px;
        font-size: 25px;
        //float: left;
        margin: -90px -95px;
        color: #9098a0;
      }

      .image {
        display: block;
        width: 200px;
        height: auto;
        border-radius: 50%;
      }

      .overlay {
        position: absolute;
        top: 50px;
        bottom: 0;
        left: 0;
        right: 0;
        height: 200px;
        width: 200px;
        opacity: 0;
        transition: .5s ease;
        background-color: rgba(0, 0, 0, 0.49);
        border-radius: 50%;
      }

      .avatar-container:hover .overlay {
        opacity: 1;
      }

      .text {
        color: white;
        font-size: 20px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-align: center;
      }
    }

    .row.text-left {
      .centered-col {
        float: left;
        margin: auto;
        padding: 10px 0 0;

        .nuclei-input-label {
          text-align: left;
          color: black;
        }

        .form-group {
          margin-bottom: 0px;
        }

        .form-control {
          border-bottom: 1px solid black;
          border-top: none;
          border-left: none;
          border-right: none;
          box-shadow: none;
          border-radius: 0;
          padding-left: 1px;
        }
      }
    }

    .profile-details {
      padding-top: 20px;
    }

    .table.table-hover {
      text-align: center;

      .fit {
        white-space: nowrap;
        width: 1%;
      }
    }

    table.table-hover tbody tr:hover td, .table-hover tbody tr:hover th {
      background-color: #9adafa;
    }

    table.table-hover thead tr:hover td, .table-hover thead tr:hover th {
      background-color: #f6c9ae;
    }

    .doc-container {
      padding: 0px;
      overflow: hidden;
      border-radius: 10px;
      margin-top: 15px;
      border: 1px solid #e3e2e0;


      .doc-title::after {
        content: '';
        width: 0px;
        height: 0px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 12px solid #656464;
        float: right;
        margin-top: 5px;
      }

      .doc-title.active::after {
        border-top: unset;
        border-bottom: 12px solid #656464;
      }

      .doc-title {
        background: #201c1c14;
        width: 100%;
        display: block;
        padding: 20px;
      }
    }

  }

  /* ---------------------------------------------------
      MEDIAQUERIES
  ----------------------------------------------------- */

  @media (max-width: 768px) {
    .slide-menu-handler{
      display: block!important;
    }
    #sidebar {
      margin-left: -1200px;
      padding-right: 20px;
    }
    #sidebar.active {
      margin-left: 0;

      .list-unstyled {
        display: grid;
        float: left;
        text-align: left;
        padding-top: 20px;
        padding-left: 50px;
      }

    }
    #sidebarCollapse span {
      display: none;
    }
  }
}

#rafle-draw {
  margin: 0px;
  padding-top: 10px;
}