.premium-label {
  margin-left: 11%;
}

.premium-price-label {
  margin-left: 11%;
  color: #ddddff;
}

.premium-bank-label {
  font-size: 9px;
  margin-left: 0%;
}
