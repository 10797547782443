.quote-form{
    background-color: #f7f7f7;
    padding: 4%;
}
#name-input, #email-input, #mobile-input {
    border: none;
    background-color: #ffffff;
    box-shadow: none;
    height: 60px;
    font-size: 12px;
    margin-bottom: 12px;
}
.prmo-details{
    text-align: left;
    padding: 2%;
}
#headStyle {
    float: left;
    margin-top: 25px;
    padding-left: 2%;
}
.terms-cond {
    text-align: left;
    margin-top: 25px;
    padding-left: 2%;
}
.terms {
    margin-left: -26px;
}
#socialIcon {
    float: left;
}

#title {
    text-align: left;
    margin-left: 20px;
    margin-bottom: 20px;
    h2 {
        color: #0094f7;
        span {
            font-weight: bold;
        }
    }
}

.email-input{
    border-radius: 5px 0px 0px 5px;
    height: 50px;
}

.bt-subscribe{
    border-radius: 0px 5px 5px 0px;
    background-color: #3093f8;
    width: 100%;
    height: 60px;
    color: white
}

.share-social{
    text-align: left;
    padding-left: 15px;
}

.share-social h2{
    font-weight: bold;
}

.share-social img{
    height: auto;
    width: 100%;
    float: left;
}
.nuclei-image-main{
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}

.nuclei-image-sub{
    width:Auto;
    margin-top:15px;
    width:100%;
    border-radius: 8px;
}
.nuclei-image-sub-one{
    margin-top:18px;
    width:100%;
    border-radius: 8px;
}

 @media (max-width: 767px){
    .quick-tag-list-left{
        padding-left: 5px;
        float: right;
    }
    .quick-tag-list-right{
        padding-right: 5px;
    }
    .quick-tags{
        padding: 15px;
    }
    .nuclei-image-sub-one{
        margin-top:8px;
        width:100%;
        border-radius: 3px;
    }
 }


 @media (max-width: 576px) { 
    .nuclei-image-main{
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    
    .nuclei-image-sub{
        width:Auto;
        margin-top:15px;
        width:100%;
        border-radius: 3px;
    }
    .nuclei-image-sub-one{
        margin-top:5px;
        width:100%;
        border-radius: 3px;
    }

 }