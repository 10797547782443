table {
  .expanded-view:hover {
    background-color:#fff!important;
  }
  .expanded-view {
    td {
      border-top-color: white!important;
    }
  }
}
