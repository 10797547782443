.tab-content {
  background-color: #eeecec;
  border-style: solid;
  border-width: 1px;
  border-color: #d1d0d0;
  border-radius: 15px;
  padding: 10px;
  margin: 10px;
  max-width: 500px;
  min-width: 200px;
}

.details {
  display: inline-flex;
  color: gray;

  .circle {
    //margin-right: -200px;
    float: left;
  }
}

.policy-number {
  float: right;
  padding: 5px;
  margin-top: 15px;

  .row {
    margin: 10px;
  }

  .detail-span {
    float: right;
    margin-top: -20px;
    margin-left: 150px;
    color: blue;
  }
}

//.total-cover {
//    float: left;
//}
//.premium {
//    float: right;
//}

.social-icon {
  float: left;
  padding: 5px;
  margin-left: 15px;

  li {
    list-style-type: none;
    display: inline;
    padding: 5px;
    size: 15px;
    color: #0094f7;
  }
}

.message-info {
  float: right;
  padding: 5px 15px 5px 15px;
  background-color: #5cb85c;
  border-radius: 20px;
  color: white;
  font-weight: bold;
}

@media (max-width: 480px) {
  .policy-number {

    .detail-span {
      margin-left: 50px;
    }
  }

  .icon {
    display: inline-block;
  }

  .details {
    display: inline-block;
  }

}

@media (max-width: 768px) {
  .policy-number {
    float: right;
    //padding-right: 45px;
    //padding-top: 180px;

    .detail-span {
      margin-left: 50px;
    }
  }

  .icon {
    display: inline-block;
  }

  .details {
    //display: inline-flex;
    color: gray;

    .circle {
      //margin-right: -200px;
      float: left;
    }
  }


}