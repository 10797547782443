
.tooltip-help{
    position: absolute;
    margin-left: 10px;
    margin-top: 0;
}

.padding-less{
    padding-left: 0;
}

.quick-quote-buttons{
    padding-top: 15px;
}


.nuclei-motor-quotation{
    padding-bottom: 100px;
}
.nuclei-motor-quotation-header{
    padding-left: 20px;
}

.quotation-dock-row{
    padding-left: 0;
}