@import "../../index";
#welcome-onboard{
        font-family: $font-family;
        text-align: left;
        p{
          font-size: 18px;
          margin-top: 20px;
        }
        a{
            text-decoration: none;
            font-size: 15px;
            margin-top: 20px;
        }
}
#payment-summary{
    text-align: left;
    font-family: $font-family;
    table{
        background-color: #f9f9f9;
      
     
        td{
            font-size: 15px;
            padding: 20px 40px 20px 10px;
            border: 5px solid #fff;
        }
    }
}

#welcome-onboard a{
    font-weight: bold;
}

#ads-images img{
    width: 100%;
    height: auto;
}